/*----------------------表單------------------------*/

   
input[type="tel"] , 
input[type="text"] , 
input[type="email"],
input[type="date"],
input[type="time"],
input[type="password"],
select,
textarea{
  -webkit-appearance: none;
  box-shadow:none;
  transition:all 0.5s;
  height: 40px;
  line-height:1.2;
  width:100%;
  background-color: white;
  padding:10px 16px;    
  letter-spacing:1px;
  border:1px solid rgba($fontcolor,0.5);
  @include border(10px);
  @media (max-width: 640px){
    font-size:pxToEm(15);
  }
}
textarea{
  height: auto;
  @include border(10px);
}
select::-ms-expand { display: none; }
::-webkit-input-placeholder {   
  @include font-color(pxToEm(14),#c1c3c3);  
}
::-moz-placeholder { /* Firefox 19+ */
  @include font-color(pxToEm(14),#c1c3c3);  
}
:-ms-input-placeholder { /* IE 10+ */
  @include font-color(pxToEm(14),#c1c3c3);  
}
:-moz-placeholder { /* Firefox 18- */
  @include font-color(pxToEm(14),#c1c3c3);  
}
@media (max-width: 640px){
  ::-webkit-input-placeholder {   
    font-size:pxToEm(16); 
  }
  ::-moz-placeholder { /* Firefox 19+ */
    font-size:pxToEm(16);
  }
  :-ms-input-placeholder { /* IE 10+ */
    font-size:pxToEm(16);
  }
  :-moz-placeholder { /* Firefox 18- */
    font-size:pxToEm(16);
  }
}

.loginbtn{
  text-align:center;
  margin-top:20px;
  a{
      @include font-color(pxToEm(18), $white);
      display:inline-block;
      margin:0 8px;
  }
  a.active,a:hover{
    color:$blue;
  }
}



