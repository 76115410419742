@import url(https://fonts.googleapis.com/earlyaccess/notosanstc.css);
$mfont: 'Microsoft JhengHei', "微軟正黑體", sans-serif;
$mfontmix: 'trebuc_1','Microsoft JhengHei', "微軟正黑體", sans-serif;
$mfontmixB: 'trebuc_b','Microsoft JhengHei', "微軟正黑體", sans-serif;
@font-face {
    font-family: "trebuc_1";
    src: url(../assets/trebuc_1.ttf);
}
@font-face {
    font-family: "trebuc_b";
    src: url(../assets/trebuc_b.ttf);
}
// @font-face {
//     font-family: "DINPro-medium";
//     src: url(../assets/DINPro-Medium.ttf);
// }
@font-face {
    font-family: "DINPro-Light";
    src: url(../assets/DINPro-Light.ttf);
}
$trebuc: 'trebuc_1', sans-serif;
$trebucB:'trebuc_b', sans-serif;
// $DINPro-m: 'DINPro-medium', sans-serif;
$DINPro: 'DINPro-Light', sans-serif;
.fenB{
    font-family:$trebuc;
}
.fen{
    font-family:$trebuc;
}
//-------------------------文字顏色-------------------------//
$white:#ffffff;
$blue:#005eb8;
$yel:#ffde00;
$green:#003831;
$fontcolor:#444853;
$menu:#2a2938;
$hover:#d1ac7d;
$bodycolor:white;

//#8f9196 line