.contact{
    background: no-repeat url('../images/line_bg/contact_bg.png') bottom center;
    h1{
        @include font-color(pxToEm(20),$green,700);
        margin-bottom: 24px;
        letter-spacing: 0.2em;
    }
    .info{
        margin-top: 140px;
        @include font-color(pxToEm(14),#444853);
        ul{
            li{
                margin-bottom: 5px;
                display: flex;
            }
            span{
                max-width:70px;
                flex:0 0 70px;
            }
        }
        .txt{
            border-top:1px solid $fontcolor;
            border-bottom:1px solid $fontcolor;
            padding:12px 10px;
            text-align: center;
            margin-top: 40px;
        }
        
        #map{
            height: 180px;
            margin-top: 20px;
        }
        @media (min-width:768px){
            .mflex{
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                > div{
                    width:50%;
                }
            }
            #map{
                max-width: 370px;
                margin-top: 0px;
            }
        }
        @media (max-width:640px){
            background: initial;     
            margin-top:30px;
            span{
                display: block;
            }
            ul li{
                margin-bottom: 20px;
                display: block;
            }
            .txt{
                padding: 10px;
                line-height: 1.8;
                margin-top: 30px;
            }
        }
    }
    .mark{            
        @include font-color(pxToEm(12),#ff1e23);
        height: 35px;
        padding-top:5px;
        padding-left: 16px;
        i{
            transform: scale(0.7);
            margin-right: 5px;
            display: inline-block;
        }
    }
    form{
        
        textarea{
            height: 200px;
        }
        .btn._send{
            margin:15px auto 0;
            width:145px;
        }
        @media (min-width:641px){
            display: flex;
            justify-content: space-around;
            align-items: center;
            flex-wrap: wrap;
            margin-top: 10px;
            > div{
                width:50%;
                &:nth-child(even){
                    padding-left: 1.25%;
                }
                &:nth-child(odd){
                    padding-right: 1.25%;
                }
                &.w100{
                    width:100%;
                    padding:0;
                }
            }
            .btn._send{
                margin:35px auto 0;
                width:80px;
            }
        }
    }
}