.portfolio{
    //row
    @media (max-width:1200px){
        .row{
            padding:0 3.8%;
            .items{
                padding:0 1%;
            }
        }
    }
    .items{
        margin-bottom: 36px;
        padding:0 0.6667%;
        .bgcover{
            padding-bottom: 51%;
        }
        .bgcolor{
            background-color: rgba($green,0.5);
            width:100%;
            height:100%;
            position: absolute;
            top:0;
            left:0;
            opacity: 0;
            @extend .default_motion;
        }
        .mtitle{
            color:$fontcolor;
            display: inline-block;
            margin-top: 12px;
            span{
                color:#8f9196;
                font-size: pxToEm(12);
                margin-left: 10px;
                font-weight: normal;
            }
            &:after{
                content:' ';
                display: block;
                width:100%;
                height: 1px;
                background-color:$green;
                margin-top: 2px;
                opacity: 0;
                @extend .default_motion;
            }
        }
        @media (min-width:641px){
            a:hover{
                .bgcolor,.more{
                    opacity: 1;
                }
                .mtitle:after{
                    opacity: 1;
                }
                .mtitle{
                    color:$green;
                    font-weight: 700;
                    span{
                        color:$green;
                    }
                }
                
            }
        }
    }
    &.mlist{
        .footerH{
            display: none;
        }
        .bgimg{
            background: no-repeat url('../images/line_bg/protfolio_list_bg.png') center top;
            padding-top: 150px;
            padding-bottom: 100px;
        }
        .items a{
            *{
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
                @extend .default_motion ;
            }
            .bgcover:before,.bgcover:after{
                width: 2px;
                height: 0;
            }
            .bgcover:before{
                right: 0;
                top: 0;
            }
            .bgcover:after{
                left: 0;
                bottom: 0;
            }
            .mimg:before,.mimg:after{
                height: 2px;
                width: 0%;
            }
            .mimg:before{
                top: 0;
                left: 0;
            }
            .mimg:after{
                bottom: 0;
                right: 0;
            }
            .mimg:after,.mimg:before,.bgcover:before,.bgcover:after{
                position: absolute;
                content: '';
                background-color:$green;
                z-index: 1;
                @extend .default_motion ;
            }
            @media (min-width:641px){
                &:hover{
                    .bgcover:before,.bgcover:after{
                        height: 100%;
                    }
                    .mimg:after,.mimg:before{
                        width:100%;
                    }
                }
            }
        }
        @media (max-width:767px){
            
            .bgimg{
                padding-top: 36px;
                background: initial;
            }
        }
    }
    &.mdetail{
        background: no-repeat url('../images/line_bg/protfolio_detail_bg.png') center top;
        h1{
            @include font-color(pxToEm(20),$green);
            margin-bottom: 60px;
        }
        .photo{
            margin-bottom: 76px;
            .bgcover{
                padding-bottom: 40%;
            }
            @media (max-width:640px){
                .bgcover{
                    padding-bottom: 85%;
                }
            }
        }
        
        ul{
            li div:nth-child(1){
                font-size: pxToEm(16);
                margin-bottom: 5px;
            }
            @media (min-width:641px){
                display:flex;
                flex-wrap: wrap;
                justify-content: space-between;
                margin-bottom: 80px;
                li{
                    width: 50%;
                    margin-bottom: 25px;
                }
            }
            @media (max-width:640px){
                margin-bottom: 40px;
                li{
                    margin-bottom: 12px;
                }
            }
        }
        .more{
            width:32px;
            height:32px;
            display: block;
            border:1px solid white;
            @include border();
            opacity: 0;
            i,i:after{
                display: block;
                background-color: white;
                @extend .pos-middle ;
            }
            i{
                width:1px;
                height:16px;
            }
            i:after{
                content:' ';
                width:16px;
                height:1px;
            }
        }
        @media (max-width:640px){   
            background: initial;         
            .photo{
                margin-bottom: 36px;
            }
            h1{
                margin-bottom: 20px;
            }
        }
    }
    .btngroup-detail{
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        max-width: 330px;
        padding:35px 0 25px;
        border-top:1px solid #8f9196;
        border-bottom:1px solid #8f9196;
        margin:0 auto;
        @media (min-width:640px){
            margin:70px auto 0;
        }
        a{
            display:block;
            font-size: pxToEm(12);
            color:$fontcolor;
            margin:0;
            &:before{
                content:' ';
                display: block;
            }
            span{
                margin-top: 12px;
                text-align: center;
            }
        }
        .prev{
            &:before{
                width:100%;
                height:14px;
                background: no-repeat url('../images/prev.png') 0 0 / 18px;
                transform: translateY(-6px);
            }
            
        }
        .next{
            &:before{
                width:100%;
                height:14px;
                background: no-repeat url('../images/next.png') 100% 0 / 18px;
                transform: translateY(-6px);
            }        
        }
        .back{
            &:before{
                width:40px;
                height:25px;
                background: no-repeat url('../images/icon_list.png') 0 0 / 40px;
                margin: 0 auto;
            }
        }
        .nopage{
            opacity: 0;
            z-index: -1;
        }
        @media (min-width:641px){
            a:hover{
                color:$green;
            }
            .prev:hover:before{
                background-position: 0 -14px;
            }
            .next:hover:before{
                background-position: 100% -14px;
            }
            .back:hover:before{
                background-position: 0 -25px;
            }
        }
    }
}

@media (max-width:860px){
    .fancybox-image, .fancybox-spaceball{
        left:30px;
        width:calc(100% - 60px);
    }
}
.fancybox-content a.close{
    border:1px solid white;
    @include border();
    z-index: 99993;
    position: absolute;
    display: block;
    width:31px;
    height: 31px;
    top:-40px;
    right:-40px;
    &:before, &:after {
        position: absolute;
        left: 14px;
        top:9px;
        content: ' ';
        height: 12px;
        width: 1px;
        background-color: white;
    }
    &:before {
        transform: rotate(45deg);
    }
    &:after {
        transform: rotate(-45deg);
    }
    @media(max-width: 992px){
        right:50%;
        transform: translateX(50%);
        top:-50px;
    }
}