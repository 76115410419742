.service{    
    @media (max-width:640px){
        background: inherit !important;
    }
    &.craft{      
        background: no-repeat url('../images/line_bg/service_bg.png') bottom center;  
        h2{
            margin:24px 0 36px;
            span{
                display: block;
                @include font-color(pxToEm(14),$fontcolor,700);
            }
        }
        ul{
            margin-bottom: 40px;
            li{
                margin-bottom: 5px;
                display: flex;
            }
            span{
                max-width:70px;
                flex:0 0 70px;
            }
            @media (max-width:640px){
                li{
                    display: block;
                    margin-bottom: 12px;
                }
                span{
                    display: block;
                    max-width: 100%;
                    flex: initial;
                }
            }
        }
        .map{            
            margin:0 auto;
            iframe{
                width:100%;
            }
            @media (max-width:640px){
                iframe{
                    height: 240px;
                }
            }
        }
        @media (min-width:991px){
            .more{
                position: absolute;
                bottom:0;
                left:15px;
            }
        }        
        @media (max-width:991px){
            .more{
                display: flex;
                justify-content: center;
                align-items: center;
                width:100%;
                margin-top: 50px;
            }
        }
        @media (max-width:640px){
            .mlogo{
                width:160px;
            }
        }
    }
    &.process{
        background: no-repeat url('../images/line_bg/service_bg.png') top center;  
        ul.tab-title{
            margin-bottom: 30px;
            li{
                margin-bottom: 22px;
            }
            a{
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                @include font-color(pxToEm(16),$fontcolor,700);
                span{
                    border-bottom:1px solid rgba($green,0);
                    line-height: 2;
                    padding-bottom: 3px;
                    @extend .default_motion ;
                }
                div{
                    display: inline-block;
                }
                i{
                    font-family: $trebuc;
                    margin-top: 5px;
                    margin-right: 3px;
                    display: inline-block;
                }
            }            
            
            @media (min-width:641px){
                a:hover{
                    color: $green;
                    span{
                        border-bottom:1px solid rgba($green,1);
                    }
                }
                li.active a{
                    color: $green;
                    span{
                        border-bottom:1px solid rgba($green,1);
                    }
                }
            }
            @media (max-width:640px){
                li{
                    margin-bottom: 0px;
                }
                li a{
                    font-size: pxToEm(15);
                    padding:4px 4.8% 4px 5.3%;
                    display: block;
                    &:after{
                        display: none;
                    }
                    i{
                        letter-spacing: 0.1em;
                        margin-right: 10px;
                    }
                }
                li.active a{
                    color:white;
                    background-color: $green;
                    display: block;
                }
                li br{
                    display: none;
                }
            }
        }
        .tab-inner{
            margin-bottom: 20px;
            h2{
                @include font-color(pxToEm(16),$green,700);
                font-family: $mfontmix;
                margin-bottom: 24px;
            }
            .txt img{
                margin-bottom: 20px;
            }
            @media (max-width:640px){
                h2{
                    font-size: pxToEm(15);
                    margin-bottom: 12px;
                }
                .txt img{
                    margin-bottom: 10px;
                }
            }
        }
        @media (max-width:640px){
            .col-12.pd0{
                padding:0;
            }
        }
    }
    &.system{
        background: no-repeat url('../images/line_bg/servicesystem_bg.png') top center;  
        .items{
            position: relative;
            z-index: 1;
           
            .mflex{
                width: 100%;
                margin-bottom: 20px;
                position: relative;
                
                &:nth-child(odd){
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: flex-end;                    
                    &:after{
                        left:0;
                    }
                }
                &:nth-child(even){
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: flex-start;
                    &:after{
                        right:0;
                    }
                    .txtbox{
                        margin-left: 10px;
                        margin-right: 0;
                        order:2;
                    }
                }
                &:after{
                    content:' ';
                    display: block;
                    width:0%;
                    height:100%;
                    background-color: $green;
                    position: absolute;
                    top:0;
                    z-index: -1;
                    transition: all 1s cubic-bezier(0.645, 0.045, 0.355, 1);
                }
                @media (min-width:992px){
                    &:nth-child(odd){
                        padding-left: 20px;
                        .txtbox{
                            margin-right: 35px;
                            margin-left:calc(3% - 35px);
                        }
                    }
                    &:nth-child(even){
                        padding-right: 20px;
                        .txtbox{
                            margin-left: 35px;
                            margin-right:calc(3% - 35px);
                        }
                    }
                    &:hover:after{
                        width:80%;
                    }
                    &:hover a.txtbox{
                        background-color: rgba(#e6e6e6,0);
                        color:white;
                        .more:after{
                            background-color:white;
                        }
                        .mtitle{
                            color: white;
                        }
                    }
                }
                @media (max-width:991px){
                    &:nth-child(odd){
                        .txtbox{
                            order:2;
                        }
                    }
                    &:nth-child(even){
                        .txtbox{
                            margin-left: 0px;
                        }
                    }
                }
            }
            @media (max-width:991px){
                max-width: 680px;
                margin:0 auto;
                padding:0 20px;
            }
            @media (max-width:640px){
                padding:0 4.8%;
            }
            .rowbox{
                width: 100%;
                display: flex;
                margin:0;
                justify-content: flex-start;
                align-items: center;
                flex-wrap: wrap;
                padding: 20px 0;
                @media (min-width:1301px){
                    max-width: 80%;
                }
                @media (max-width:640px){
                    padding:0;
                }
            }            
            a.txtbox{
                background-color: #e6e6e6;
                color:$fontcolor;
                padding:10px;
                width: 20%;
                margin-right: 2%;
                transition-delay: 0.3s;
                .bdr{
                    border:2px solid white;
                    width: 100%;   
                    height: 100%;  
                    position: relative;
                    
                }
                p{
                    margin-top: 15%;
                    overflow: hidden;

                }
                .mtitle{
                    color:$green;
                    font-size: pxToEm(16);
                    margin-bottom: 5px;
                    display: block;
                    transition: all 0.6s;
                    transition-delay: 0.3s;
                }
                .more{
                    font-size: pxToEm(12);
                    padding:10px 0px;
                    display: inline-block;
                    position: relative;                   
                    &:after{
                        content:' ';
                        display: inline-block;
                        width:100%;
                        height: 1px;
                        background-color:#e6e6e6;
                        transition: all 0.6s;
                        transition-delay: 0.3s;
                        position: absolute;
                        bottom:5px;
                        left:0;
                    }
                }
                @media (min-width:992px){
                    p{
                        height: calc(4.5em + 26px);
                    }
                    .bdr{
                        height: 0;
                        padding:0 15px;
                        padding-bottom: calc(100% - 3px);
                    }
                    .more{
                        position: absolute;
                        bottom:16px;
                    }
                }
                @media (max-width:991px){
                    // width:35%;
                    flex:0 0 100%;
                    // height: 200px;
                    // margin-right: 10px;
                    // margin-left:0;
                    margin:4px 0 0;
                    p{
                        margin-top: 0;
                        margin:0 0 45px;
                    }
                    p span:nth-child(2){
                        height: 4.5em;
                        overflow: hidden;
                        display: block;
                    }
                    .bdr{
                        padding:15px;
                    }
                    .more{
                        border:1px solid $green;
                        @include border(10px);
                        max-width: 100px;
                        width:100%;
                        height: 40px;
                        line-height: 40px;
                        padding:0;
                        text-align: center;
                        color:$green;
                        position: absolute;
                        bottom:10px;
                        margin:0;
                        right:10px;
                    }
                }
                @media (max-width:640px){
                    // padding:5px;
                    // margin-right: 5px;
                    // .bdr{
                    //     padding:20px 5px 0;
                    //     border-width: 1px;
                    // }
                }
            }
            .imgbox{
                
                width: 77%;
                .bgcover{
                    padding-bottom: 62%;
                }
                // .mimg{
                //     width:100%;
                // }
                // @media (min-width:992px){
                    display: flex;
                    .mimg{
                        width:calc((100% - 4px) / 2);
                        &:nth-child(1){
                            margin-right: 2px;
                        }
                        &:nth-child(2){
                            margin-left: 2px;
                        }
                    }
                // }
                @media (max-width:991px){
                    // width:65%;
                    flex:0 0 100%;
                    // .mimg{
                    //     &:nth-child(1){
                    //         margin-bottom: 10px;
                    //     }
                    // }
                }
                @media (max-width:991px){
                    // .mimg{
                    //     &:nth-child(1){
                    //         margin-bottom: 5px;
                    //     }
                    // }
                }
            }
        }
    }
}