/*----------------------通用設定------------------------*/
//清除float浮動
%cf,main[role^=main]  {
    zoom: 1;
    &:before,
    &:after {
        content: "";
        display: table;
        height: 0;
        overflow: hidden;
    }
    &:after {
        clear: both;
    }
}
.clearfix {
  @include clearfix();
}

//selection
p,h1,h2,h3,h4,b,strong,span,li,div,a,img{
    &::selection{
          color:#fff;
          background:#000;
        }
}
//a tag
a{outline: none;}
a,a:hover {
  text-decoration: none;
  transition: 0.4s;
  color:initial;
}
//phone
a[href^=tel] {
  color:$fontcolor;
  cursor: default;
  &:hover{
    color:$fontcolor;
  }
}
*:focus {
    outline: none;
}
img {
    vertical-align: middle;
    max-width: 100%;
}
ul{
  list-style-type: none;
}
//border-box
*,
*:before,
*:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

/*----------------------編輯器------------------------*/
.editor_Box{
    @extend %cf;
    width: 100%;
    margin: 0 auto;
    font-size: pxToEm(14);
    line-height: 1.7;
    letter-spacing: 1.4px;
    color: $menu;
    ul,ol{
        padding-left: 40px;
    }
    img{
        max-width: 100%;
        height: auto !important;
        @media screen and (max-width: 768px) {
          width:100%;
        }
    }
    iframe{
         max-width: 100%;
          @media screen and (max-width: 767px) {
            height: auto !important;
          }
    }
    h1,h2,h3,h4,h5,h6,{
        margin: inherit;
    }
    p{
        margin-bottom: 18px;
        &:last-child{
            margin-bottom: 0;
        }
    }
}
/*----------------------頁籤------------------------*/
.page{
    clear: both;
    width: 100%;
    text-align: center;
    padding:0 20px;
    dd {
        display: inline-block;
        a{
          font-size: pxToEm(12);
          letter-spacing: 1.7px;
          display: block;
          margin:0 18px;
          padding-bottom: 3px;
          line-height: 19px;
          border-bottom:1px solid #8f9196;
          color:#8f9196;
        }
        a.active{
          border-bottom:1px solid $green;
          color:$green;
        }
    }
    //prev,next
    dt{
      display: inline-block;   
      // vertical-align: -5px; 
      a{
        display: block;
        transition: 0ms;
      }
      &.ltbn a{
        width:18px;
        height:14px;
        background: no-repeat url('../images/prev.png') 0 0 / 18px;
      }
      &.rtbn a{
        width:18px;
        height:14px;
        background: no-repeat url('../images/next.png') 100% 0 / 18px;
      }
    }
    @media (min-width: 641px) {
        dd a:hover{
          border-bottom:1px solid $green;
          color:$green;
        }
        dt{
          &.ltbn a:hover{
            background-position: 0 -14px;
          }
          &.rtbn a:hover{
            background-position: 100% -14px;
          }
        }
    }
    @media (max-width:640px){
      display: flex;
      justify-content: space-around;
      align-items: center;
    }
    dd.active a{
        color:#333;
        font-weight: 500;
        border-bottom:1px solid #333;
    }
    .nopage{
      opacity: 0;
      z-index: -1;
    }
    
}
/*----------------------go top------------------------*/
#topBtn{
  
  display:block;
  z-index:1;
  position: fixed;
  right:15px;
  bottom:15px;
  transition:all 0.5s;
  opacity: 0;
  visibility: hidden;
  @include font-color(pxToEm(16),#444853);
  background: no-repeat url('../images/gotop.png') 0 / 35px;
  width: 35px;
  height:80px;
  &.open{
    opacity: 1;
    visibility: visible;
    z-index:100;
  }
  &.open{
    opacity: 1;
    z-index:100;
  }
  &.change{
    position: absolute;
  }
  @media (min-width: 641px) {
    &:hover{
      background: no-repeat url('../images/gotoph.png') 0 / 35px;
    }
  }  
  @media (max-width:640px){
    background-size: 35px;
    line-height: 28px;
    width:35px;
    height: 80px;
    font-size: pxToEm(14);
  }
}
// custom
.text-center{text-align: center;}
.text-right{text-align:right;}
.text-justify{text-align: justify;}
.right{float:right;}
.left{float:left;}
.mpsrel{position: relative;}
.fwhite{color:$white;}
.hide{display:none;}
.w10img{width:100%;}
.bk{
  display: block;
  width:100%;
}

%shadow{
  text-shadow: 2px 2px 3px #434a54;
}

@media (min-width:641px){
  .show-sm{display:none;}
}
@media (max-width:640px){
  .show-lg{display:none;} 
}
@include media-breakpoint-down(sm) {
  span.br{
      display:block;
    }
}