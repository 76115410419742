.about{
    background: no-repeat url('../images/line_bg/about_bg.png') top center ;
    @media (max-width:640px){
        background: inherit;
        .w800{
            padding: 0 4.8%;
            .rowbox{
                padding:0;
            }
        }
    }
    ul.infolist{
        display:flex;
        flex-wrap: wrap;
        justify-content: space-between;
        li{
            width: 50%;
            margin-bottom: 20px;
        }
        
        @media (max-width:640px){
            // margin-bottom: 40px;
            li{
                margin-bottom: 12px;
            }
        }
    }
    ul.tableist{
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 40px;
        
        li{
            border:1px solid white;
            padding:6px 10px;
            text-align: center;
        }
        li:nth-child(odd){
            width:40%;
            background-color: #e6e6e6;
        }
        li:nth-child(even){
            width:10%;
            background-color: #dadada;
        }
        li:nth-child(-n+4){
            background-color:#cdcdcd;
        }
        @media (max-width:640px){
            li:nth-child(-n+2){
                display: none;
            }
            li{
                padding:6px;
            }
            li:nth-child(odd){
                width:78%;
            }
            li:nth-child(even){
                width:22%;
            }
        }
    }
    h4{
        font-size: pxToEm(16);
        color:$green;
        margin:35px 0 12px;
        @media(max-width: 640px){
            font-size: pxToEm(15);
            margin:20px 0 6px;
        }
    }
    .figure_items{
        figure{
            display:flex;
            align-items: center;
            margin-top: 30px;
            &:nth-child(odd) .txtbox{
                background-color:#e6e6e6;
            }
            &:nth-child(even) .txtbox{
                background-color:rgba(#a8c1a1,0.6);
                color:$green;
            }
            &:nth-child(even) .mimg{
                order:2;
            }
        }
        .mimg{
            width:62.5%;
        }
        .wm80{
            width:80px;//ideal
        }
        .txtbox{
            width:37.5%;
            padding:12px;
            figcaption{
                border:2px solid white;
                width:100%;
                height: 0;;
                padding-bottom: calc(80% - 4px);
                text-align: center;
                position: relative;
                > div{
                    width: 100%;
                }
            }
        }
        .bgcover{
            padding-bottom: 66%;
        }
        @media (max-width:640px){
            .txtbox{
                padding:5px;
                font-size: pxToEm(12);
                letter-spacing: 0;
                figcaption{
                    border-width: 1px;
                }
            }
            .wm80{
                width:50px;//ideal
            }
        }
    }
    .mb30{
        margin-bottom: 30px;
    }
    .ideal{
        
        &:after{
            content:' ';
            display: block;
            width:100%;
            height: 23px;
            background:url(../images/about/boxshadow.png) bottom / contain;
            position: absolute;
            bottom:-22px;
            left:0;
            z-index: 1;
        }
        @media (min-width:641px){
            display: flex;
            align-items: stretch;
            justify-content: space-around;
            margin-bottom: 130px;
            > div{
                
            
            }
            .txt{
                padding:0 40px 20px 5px;
                //box-shadow: 6px 5px 5px rgba(0,0,0,.2);
                min-height:455px ;
                flex:0 0 46%;
                text-align: justify;
            }
            .mimg{
                box-shadow: 0px 5px 5px rgba(0,0,0,.1);
                background-color: white;
                flex:0 0 54%;
                order:2;
                .pos{
                    width: 90%;
                    position: absolute;
                    top:50%;
                    left:50%;
                    transform: translate(-50%,-50%);
                }
            }
        }
        @media (max-width:640px){
            padding:0;
            .txt{
                padding:0 4.8%;
                margin-top: 30px;
            }
        }
    }
    .chronology{
        @media (max-width:991px) and (min-width:641px){
            padding:0 20px;
            max-width: 680px;
        }
        .figure{
            @media (min-width:992px){
                position: relative;
                display: flex;
                justify-content: space-around;
                align-items: center;
                .mimg,.figcaption{
                    width: calc(50% - 50px);
                    flex:0 0 calc(50% - 50px);
                }   
                .myear{
                    max-width: 100px;
                    flex:0 0 100px;
                    position: relative;
                    font-size: pxToEm(16);
                }
                &:nth-child(odd){
                    .myear{
                        order:2;
                    }
                    .mimg{
                        order:1;
                        padding-right: 2.5%;
                    }
                    .figcaption{
                        order:3;
                        padding-left: 2.5%;
                        text-align: left;
                    }
                } 
                &:nth-child(even){
                    .myear{
                        order:2;
                    }
                    .mimg{
                        order:3;
                        padding-left: 2.5%;
                    }
                    .figcaption{
                        order:1;
                        padding-right: 2.5%;
                        text-align: right;
                    }
                    .figcaption > div{
                        float: right;
                    }
                }  
            }  
            &:nth-child(even){
                .circle{
                    background-color: $green;
                    color:white;
                }
            }
        }
        .line{
            width:1px;
            height: 85px;
            margin: 0 auto;
            background-color: $green;            
            @media (min-width:992px){
                position: absolute;
                left:50%;
                top:0;
                height: 100%;
                z-index: -1;
            }
        }
        
        .circle{
            width:70px;
            height:70px;
            line-height: 70px;
            text-align: center;
            border: 1px solid $green;
            @include border();
            background-color: white;
            color:$green;
            margin:0 auto 15px;
            position: relative;
            @extend .fen;
            @media (min-width:992px){
                position: absolute;
                top:50%;
                left:50%;
                transform: translate(-50%,-50%);
            }
            &:after{
                content: ' ';
                display: block;
                width:12px;
                height: 12px;
                // background: no-repeat url('../images/about/arrow.svg');
                border-left:1px solid $green;
                border-bottom:1px solid $green;
                position: absolute;
                top:calc(50% - 43px);
                left:50%;
                transform: translate(-50%,-50%) rotate(-45deg);
            }
        }
        .bgcover{
            padding-bottom: 48%;
        }
        .figcaption{
            padding-top:10px;
            padding-bottom:25px;
            text-align: center;
            span{
                @include font-color(pxToEm(16),$green,700);
            }
            
            // @media (min-width:1201px){
            //     > div{
            //         width:80%;
            //         max-width: 400px;
            //     }
            // }
        }
        .conclusion{
            text-align: center;
            padding-top: 150px;
            position: relative;
            @include font-color(pxToEm(18),$green);
            letter-spacing: 0.2em;
            .line{
                height: 110px;
                &:after{
                    content: ' ';
                    display: block;
                    width:12px;
                    height: 12px;
                    border-left:1px solid $green;
                    border-bottom:1px solid $green;
                    position: absolute;
                    bottom:0;
                    left:50%;
                    transform: translate(-50%,0%) rotate(-45deg);
                }
            }
            @media (max-width:991px){               
                padding-top: 0;
                font-size: pxToEm(15);
                .line{
                    height: 85px;
                    margin-bottom: 15px;
                    position: relative;
                }
            }
        }
    }
}