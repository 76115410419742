@charset "UTF-8";
/******************************************/
/*		bootstrap-okadmin
/******************************************/
@import url(plug/jquery.fancybox.min.css);
@import url(https://fonts.googleapis.com/earlyaccess/notosanstc.css);
.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .form-control::placeholder {
    color: #868e96;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

select.form-control:not([size]):not([multiple]) {
  height: calc(2.25rem + 2px); }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

select.form-control-sm:not([size]):not([multiple]) {
  height: calc(1.8125rem + 2px); }

.form-control-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control-lg:not([size]):not([multiple]) {
  height: calc(2.875rem + 2px); }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input:disabled ~ .form-check-label {
    color: #868e96; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  width: 250px;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.8);
  border-radius: .2rem; }

.was-validated .form-control:valid, .form-control.is-valid, .was-validated
.custom-select:valid,
.custom-select.is-valid {
  border-color: #28a745; }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus, .was-validated
  .custom-select:valid:focus,
  .custom-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  .was-validated .form-control:valid ~ .valid-feedback,
  .was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
  .form-control.is-valid ~ .valid-tooltip, .was-validated
  .custom-select:valid ~ .valid-feedback,
  .was-validated
  .custom-select:valid ~ .valid-tooltip,
  .custom-select.is-valid ~ .valid-feedback,
  .custom-select.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    background-color: #71dd8a; }

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  background-color: #34ce57; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745; }
  .was-validated .custom-file-input:valid ~ .custom-file-label::before, .custom-file-input.is-valid ~ .custom-file-label::before {
    border-color: inherit; }

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  width: 250px;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.8);
  border-radius: .2rem; }

.was-validated .form-control:invalid, .form-control.is-invalid, .was-validated
.custom-select:invalid,
.custom-select.is-invalid {
  border-color: #dc3545; }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, .was-validated
  .custom-select:invalid:focus,
  .custom-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
  .was-validated .form-control:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip, .was-validated
  .custom-select:invalid ~ .invalid-feedback,
  .was-validated
  .custom-select:invalid ~ .invalid-tooltip,
  .custom-select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    background-color: #efa2a9; }

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  background-color: #e4606d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545; }
  .was-validated .custom-file-input:invalid ~ .custom-file-label::before, .custom-file-input.is-invalid ~ .custom-file-label::before {
    border-color: inherit; }

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

/* > reset */
*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

@-ms-viewport {
  width: device-width; }

article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

dfn {
  font-style: italic; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }
  a:hover {
    color: #0056b3;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):focus, a:not([href]):not([tabindex]):hover {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg:not(:root) {
  overflow: hidden; }

a,
area,
button,
[role="button"],
input:not([type="range"]),
label,
select,
summary,
textarea {
  touch-action: manipulation; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #868e96;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: .5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.border {
  border: 1px solid #e9ecef !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #007bff !important; }

.border-secondary {
  border-color: #868e96 !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-0 {
  border-radius: 0 !important; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0; }

.slick-list:focus {
  outline: none; }

.slick-list.dragging {
  cursor: pointer;
  cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block; }

.slick-track:before,
.slick-track:after {
  display: table;
  content: ''; }

.slick-track:after {
  clear: both; }

.slick-loading .slick-track {
  visibility: hidden; }

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px; }

[dir='rtl'] .slick-slide {
  float: right; }

.slick-slide img {
  display: block; }

.slick-slide.slick-loading img {
  display: none; }

.slick-slide.dragging img {
  pointer-events: none; }

.slick-initialized .slick-slide {
  display: block; }

.slick-loading .slick-slide {
  visibility: hidden; }

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

.pc_Banner .slick-slide {
  height: 371px; }

.mo_Banner .slick-slide {
  height: 200px; }

/* Slider */
/* Icons */
@font-face {
  font-family: 'slick';
  font-weight: normal;
  font-style: normal;
  src: url("../assets/slick.eot");
  src: url("../assets/slick.eot?#iefix") format("embedded-opentype"), url("../assets/slick.woff") format("woff"), url("../assets/slick.ttf") format("truetype"), url("../assets/slick.svg#slick") format("svg"); }

/* Arrows */
.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
  z-index: 99; }

/*.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus
{
    color: transparent;
    outline: none;
    background: transparent;
}*/
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1; }

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 1; }

.slick-prev:before,
.slick-next:before {
  font-family: 'slick';
  font-size: 20px;
  line-height: 1;
  opacity: 1;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px;
  /*    background: url(../images/are.png) 8px 0 no-repeat;*/ }

.slick-next {
  right: -25px;
  /*    background: url(../images/are.png) -10px 0 no-repeat;*/ }

/* Dots */
.slick-dots {
  position: absolute;
  display: block;
  width: 100%;
  padding: 0;
  margin: -39px 0 0 0;
  list-style: none;
  text-align: center; }

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 11px;
  height: 11px !important;
  margin: 0 10px;
  padding: 0;
  cursor: pointer; }

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent; }

.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
  background: transparent !important; }

.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1; }

.slick-dots li button:before {
  font-size: 6px;
  line-height: 20px;
  display: table;
  position: absolute;
  top: 2px;
  left: 2px;
  width: 7px;
  height: 7px;
  -webkit-border-radius: 99em;
  -moz-border-radius: 99em;
  border-radius: 99em;
  background: #fff;
  content: '';
  text-align: center;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.slick-dots li.slick-active button:before {
  background: #000; }

.topContent .slick-next, .topContent .slick-prev {
  display: none  !important; }

.topContent .slick-dots li {
  width: 20px; }

.topContent .slick-dots {
  position: relative;
  margin: 29px 0 0 0; }

.tagContent .slick-dots {
  margin: 0px 0 0 0; }

.tagContent .slick-dots li button:before {
  border: none; }

.exposeCase .topicContent .slick-dots {
  margin: 0px 0 0 0; }

.exposeCase .topicContent .slick-dots li {
  width: 20px; }

.exposeCase .topicContent .slick-dots li button:before {
  border: none;
  background: #959595; }

.exposeCase .topicContent .slick-dots li.slick-active button:before {
  border: none;
  background: #6bad3d; }

.learningBox .exposeCase .topicContent li {
  margin: 0px 4px 20px 0; }

.exposeCase .slick-prev {
  width: 46px;
  height: 108px;
  display: block;
  /* background: url(../images/le_ribtn.jpg) top left no-repeat; */
  left: -46px; }

.exposeCase .slick-next {
  width: 46px;
  height: 108px;
  display: block;
  /* background: url(../images/ex_ribtn.jpg) top left no-repeat; */
  right: -29px; }

.navigation .tagContent .slick-dots {
  margin: -13px 0 0 0;
  width: auto;
  text-align: right;
  position: relative;
  bottom: -11px; }

.eventBox .navigation .tagContent .slick-dots {
  margin: -13px 0 0 0;
  width: auto;
  text-align: right;
  position: relative;
  bottom: -26px; }

.navigation .slick-dots li button:before {
  background: #959595; }

.navigation .slick-dots li.slick-active button:before {
  background: #6bad3d; }

.recentBox .slick-dots {
  display: none !important; }

.recentBox .slick-list {
  width: 90%;
  max-width: 1000px;
  margin: 0 auto; }

.banner .slick-prev, .recentBox .slick-prev {
  left: 0px;
  width: 40px;
  height: 93px; }

.banner .slick-next, .recentBox .slick-next {
  right: 0px;
  width: 40px;
  height: 93px; }

/******************************************/
/*		base 歸零 變數
/******************************************/
/* http://meyerweb.com/eric/tools/css/reset/ */
/* v1.0 | 20080212 */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
sub,
sup,
tt,
var,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
caption,
tfoot,
thead,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  /* vertical-align: baseline; */ }

tr,
th,
td {
  margin: 0;
  padding: 0;
  font-size: 100%;
  font: inherit; }

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

body {
  line-height: 1; }

/*ol, ul {
	list-style: none;
}*/
blockquote,
q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none; }

/*背景沒連結時用*/
.myMOUSE {
  cursor: default; }

.error {
  font-size: 12px;
  color: #fc7314; }

@font-face {
  font-family: "trebuc_1";
  src: url(../assets/trebuc_1.ttf); }

@font-face {
  font-family: "trebuc_b";
  src: url(../assets/trebuc_b.ttf); }

@font-face {
  font-family: "DINPro-Light";
  src: url(../assets/DINPro-Light.ttf); }

.fenB {
  font-family: "trebuc_1", sans-serif; }

.fen, .about .chronology .circle {
  font-family: "trebuc_1", sans-serif; }

/******************************************/
/*		layout 共用
/******************************************/
/*----------------------通用設定------------------------*/
.editor_Box, main[role^=main] {
  zoom: 1; }
  .editor_Box:before, .editor_Box:after, main[role^=main]:before, main[role^=main]:after {
    content: "";
    display: table;
    height: 0;
    overflow: hidden; }
  .editor_Box:after, main[role^=main]:after {
    clear: both; }

.clearfix::after, .rowbox::after {
  display: block;
  clear: both;
  content: ""; }

p::selection, h1::selection, h2::selection, h3::selection, h4::selection, b::selection, strong::selection, span::selection, li::selection, div::selection, a::selection, img::selection {
  color: #fff;
  background: #000; }

a {
  outline: none; }

a, a:hover {
  text-decoration: none;
  transition: 0.4s;
  color: initial; }

a[href^=tel] {
  color: #444853;
  cursor: default; }
  a[href^=tel]:hover {
    color: #444853; }

*:focus {
  outline: none; }

img {
  vertical-align: middle;
  max-width: 100%; }

ul {
  list-style-type: none; }

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

/*----------------------編輯器------------------------*/
.editor_Box {
  width: 100%;
  margin: 0 auto;
  font-size: 0.875rem;
  line-height: 1.7;
  letter-spacing: 1.4px;
  color: #2a2938; }
  .editor_Box ul, .editor_Box ol {
    padding-left: 40px; }
  .editor_Box img {
    max-width: 100%;
    height: auto !important; }
    @media screen and (max-width: 768px) {
      .editor_Box img {
        width: 100%; } }
  .editor_Box iframe {
    max-width: 100%; }
    @media screen and (max-width: 767px) {
      .editor_Box iframe {
        height: auto !important; } }
  .editor_Box h1, .editor_Box h2, .editor_Box h3, .editor_Box h4, .editor_Box h5, .editor_Box h6 {
    margin: inherit; }
  .editor_Box p {
    margin-bottom: 18px; }
    .editor_Box p:last-child {
      margin-bottom: 0; }

/*----------------------頁籤------------------------*/
.page {
  clear: both;
  width: 100%;
  text-align: center;
  padding: 0 20px; }
  .page dd {
    display: inline-block; }
    .page dd a {
      font-size: 0.75rem;
      letter-spacing: 1.7px;
      display: block;
      margin: 0 18px;
      padding-bottom: 3px;
      line-height: 19px;
      border-bottom: 1px solid #8f9196;
      color: #8f9196; }
    .page dd a.active {
      border-bottom: 1px solid #003831;
      color: #003831; }
  .page dt {
    display: inline-block; }
    .page dt a {
      display: block;
      transition: 0ms; }
    .page dt.ltbn a {
      width: 18px;
      height: 14px;
      background: no-repeat url("../images/prev.png") 0 0/18px; }
    .page dt.rtbn a {
      width: 18px;
      height: 14px;
      background: no-repeat url("../images/next.png") 100% 0/18px; }
  @media (min-width: 641px) {
    .page dd a:hover {
      border-bottom: 1px solid #003831;
      color: #003831; }
    .page dt.ltbn a:hover {
      background-position: 0 -14px; }
    .page dt.rtbn a:hover {
      background-position: 100% -14px; } }
  @media (max-width: 640px) {
    .page {
      display: flex;
      justify-content: space-around;
      align-items: center; } }
  .page dd.active a {
    color: #333;
    font-weight: 500;
    border-bottom: 1px solid #333; }
  .page .nopage {
    opacity: 0;
    z-index: -1; }

/*----------------------go top------------------------*/
#topBtn {
  display: block;
  z-index: 1;
  position: fixed;
  right: 15px;
  bottom: 15px;
  transition: all 0.5s;
  opacity: 0;
  visibility: hidden;
  font-size: 1rem;
  color: #444853;
  font-weight: normal;
  background: no-repeat url("../images/gotop.png") 0/35px;
  width: 35px;
  height: 80px; }
  #topBtn.open {
    opacity: 1;
    visibility: visible;
    z-index: 100; }
  #topBtn.open {
    opacity: 1;
    z-index: 100; }
  #topBtn.change {
    position: absolute; }
  @media (min-width: 641px) {
    #topBtn:hover {
      background: no-repeat url("../images/gotoph.png") 0/35px; } }
  @media (max-width: 640px) {
    #topBtn {
      background-size: 35px;
      line-height: 28px;
      width: 35px;
      height: 80px;
      font-size: 0.875rem; } }

.text-center {
  text-align: center; }

.text-right {
  text-align: right; }

.text-justify {
  text-align: justify; }

.right {
  float: right; }

.left {
  float: left; }

.mpsrel {
  position: relative; }

.fwhite {
  color: #ffffff; }

.hide {
  display: none; }

.w10img {
  width: 100%; }

.bk {
  display: block;
  width: 100%; }

@media (min-width: 641px) {
  .show-sm {
    display: none; } }

@media (max-width: 640px) {
  .show-lg {
    display: none; } }

@media (max-width: 767.99px) {
  span.br {
    display: block; } }

.headerbg {
  max-width: 1200px;
  margin: 0 auto;
  position: relative; }

header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1011;
  width: 100%;
  background-color: white;
  transition: top 0.6s ease; }
  header .logo {
    position: absolute;
    left: 0px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 11;
    display: flex;
    justify-content: center;
    align-items: center; }
    header .logo img {
      width: 240px; }
    @media (max-width: 1365px) {
      header .logo {
        left: 30px; } }
    @media (max-width: 640px) {
      header .logo img {
        width: 140px; } }
  header ul.mmenu {
    display: flex;
    justify-content: flex-end; }
    header ul.mmenu > li:not(.langubtn) {
      margin-left: 15px; }
      header ul.mmenu > li:not(.langubtn) > a {
        position: relative;
        display: block;
        text-align: center;
        font-size: 0.875rem;
        color: #444853;
        font-weight: inherit;
        -webkit-writing-mode: vertical-rl;
        writing-mode: vertical-rl;
        line-height: 30px;
        width: 25px;
        letter-spacing: 0.3em; }
      header ul.mmenu > li:not(.langubtn) a:after {
        content: ' ';
        width: 100%;
        height: calc(100% - 0.4em);
        position: absolute;
        top: 0%;
        left: 0;
        border-left: 1px solid rgba(0, 56, 49, 0);
        z-index: -1; }
      header ul.mmenu > li:not(.langubtn).active > a {
        color: #003831; }
      header ul.mmenu > li:not(.langubtn).active > a:after {
        border-left: 1px solid #003831; }
      header ul.mmenu > li:not(.langubtn).hasmenu > a:before {
        content: "";
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 8px 14px 8px;
        border-color: transparent transparent #e6e6e6;
        position: absolute;
        bottom: -18px;
        left: 50%;
        transform: translateX(-50%);
        opacity: 0; }
      @media all and (-ms-high-contrast: none) {
        header ul.mmenu > li:not(.langubtn) a:after {
          display: none; }
        header ul.mmenu > li:not(.langubtn) span {
          border-left: 1px solid rgba(0, 56, 49, 0);
          width: 28px; }
        header ul.mmenu > li:not(.langubtn).hasmenu > a:before {
          bottom: 35px;
          left: 9px; } }
    header ul.mmenu li.langubtn {
      height: 85px;
      margin-left: 15px; }
      header ul.mmenu li.langubtn a {
        display: block;
        width: 32px;
        height: 22px;
        line-height: 20px;
        border: 1px solid #444853;
        background-color: rgba(255, 255, 255, 0);
        font-size: 0.875rem;
        color: #444853;
        font-weight: inherit;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        border-radius: 5px;
        text-align: center; }
      header ul.mmenu li.langubtn a:nth-child(1) {
        margin-bottom: 10px; }
      header ul.mmenu li.langubtn a.active {
        color: white;
        background-color: #003831; }
    header ul.mmenu a span {
      position: relative; }
    @media (min-width: 1366px) {
      header ul.mmenu {
        transform: translateX(55px); } }
    @media (min-width: 992px) {
      header ul.mmenu {
        height: 175px;
        padding-top: 50px; }
        header ul.mmenu > li:not(.langubtn) > a:not(*:root) {
          position: relative;
          display: flex;
          justify-content: flex-start;
          align-items: center; }
        header ul.mmenu > li:not(.langubtn):hover > a {
          color: #003831; }
        header ul.mmenu > li:not(.langubtn):hover > a:after {
          border-left: 1px solid #003831; }
        header ul.mmenu > li:not(.langubtn):hover > a:before {
          opacity: 1; }
        header ul.mmenu > li:not(.langubtn):hover ul.subBox {
          visibility: visible;
          opacity: 1; } }
    @media (min-width: 992px) and (-ms-high-contrast: none) {
      header ul.mmenu > li:not(.langubtn) > a {
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start !important; }
      header ul.mmenu > li:not(.langubtn):hover span {
        border-left: 1px solid #003831; } }
    @media (max-width: 991px) {
      header ul.mmenu {
        align-items: flex-start;
        justify-content: center;
        position: relative;
        height: 280px; }
        header ul.mmenu > li:not(.langubtn) {
          margin: 0 10px; }
          header ul.mmenu > li:not(.langubtn) > a {
            line-height: 30px;
            width: 30px; }
          header ul.mmenu > li:not(.langubtn).active ul.subBox {
            visibility: visible;
            opacity: 1; } }
    @media (max-width: 991px) and (-ms-high-contrast: none) {
      header ul.mmenu > li:not(.langubtn) span {
        position: absolute;
        top: 0; }
      header ul.mmenu > li:not(.langubtn).hasmenu.active span {
        border-left: 1px solid #003831; } }
    @media (max-width: 640px) {
      header ul.mmenu li.langubtn {
        position: absolute;
        right: 25px; } }
  header ul.subBox {
    position: absolute;
    z-index: 10;
    opacity: 0;
    visibility: hidden;
    display: block; }
    header ul.subBox li {
      float: left;
      margin: 0 5px; }
    header ul.subBox a {
      display: block;
      text-align: center;
      font-size: 0.875rem;
      color: #444853;
      font-weight: inherit;
      height: 35px;
      line-height: 35px;
      padding: 0 10px;
      text-align: right; }
    header ul.subBox a.active {
      background-color: #003831;
      color: white; }
    @media (min-width: 992px) {
      header ul.subBox {
        bottom: 0px; }
        header ul.subBox a:hover, header ul.subBox a.active {
          background-color: #003831;
          color: white; } }
    @media (max-width: 991px) {
      header ul.subBox {
        top: 120px;
        padding-top: 25px;
        border-top: 1px solid #444853;
        min-width: 180px; }
        header ul.subBox li {
          margin: 0 20px 0 0; }
        header ul.subBox a {
          height: auto;
          -webkit-writing-mode: vertical-rl;
          writing-mode: vertical-rl;
          line-height: 30px;
          width: 30px;
          letter-spacing: 0.3em;
          padding: 15px 0;
          -webkit-border-radius: 5px;
          -moz-border-radius: 5px;
          border-radius: 5px; } }
    @media (max-width: 991px) and (-ms-high-contrast: none) {
      header ul.subBox {
        display: flex;
        height: 40vh; }
        header ul.subBox li {
          position: relative;
          width: 30px; }
        header ul.subBox a {
          position: absolute;
          top: 0;
          left: 0; } }
  header .menuicon {
    position: absolute;
    right: 0;
    height: 100%;
    z-index: 10;
    width: 40px;
    height: 30px;
    padding-right: 20px;
    margin-right: 20px;
    margin-top: 40px; }
    header .menuicon .mpsrel {
      width: 100%;
      height: 100%; }
    header .menuicon .bar, header .menuicon .bar:after, header .menuicon .bar:before {
      transition: all .25s;
      content: "";
      position: absolute;
      right: 0;
      bottom: 0;
      height: 25px;
      width: 1px;
      background: #444853; }
    header .menuicon .bar:after {
      right: 10px;
      height: 30px; }
    header .menuicon .bar:before {
      right: -10px;
      height: 30px; }
    header .menuicon.menu-expanded .bar {
      background: rgba(68, 72, 83, 0); }
      header .menuicon.menu-expanded .bar:before {
        right: 0;
        transform: rotate(-45deg);
        background-color: #444853; }
      header .menuicon.menu-expanded .bar:after {
        right: 0;
        transform: rotate(45deg);
        background-color: #444853; }
    @media (min-width: 992px) {
      header .menuicon {
        display: none; } }
    @media (max-width: 640px) {
      header .menuicon {
        margin-top: 17px; } }
  @media (min-width: 992px) {
    header .bggray {
      display: block;
      width: 100%;
      height: 35px;
      background-color: #e6e6e6;
      position: absolute;
      bottom: 0px;
      left: 0;
      opacity: 0;
      visibility: hidden;
      z-index: -1; }
    header .bggray.mhover {
      visibility: visible;
      opacity: 1; }
    header .menu_switch {
      position: fixed;
      top: 50px;
      right: 30px;
      cursor: pointer;
      opacity: 0;
      visibility: hidden;
      transition: all 0.6s;
      z-index: 999;
      width: 30px;
      display: block;
      text-align: center;
      line-height: 1.3; }
      header .menu_switch span {
        margin-top: 5px;
        display: inline-block; }
    header.nav-up {
      top: -195px; }
      header.nav-up .menu_switch {
        opacity: 1;
        visibility: visible; } }
  @media (min-width: 992px) and (max-width: 1365px) {
    header .navBox {
      padding-right: 30px; } }
  @media (max-width: 991px) {
    header .headBox {
      position: relative;
      height: 100px;
      width: 100%;
      background-color: white;
      z-index: 10; }
    header .navBox {
      display: none;
      width: 100%;
      height: 80vh;
      padding-top: 20vh;
      position: absolute;
      top: 100px;
      left: 0;
      z-index: 9;
      background-color: #e6e6e6; }
    header .menu_switch {
      display: none; } }
  @media (max-width: 640px) {
    header .headBox {
      height: 60px; }
    header .navBox {
      top: 60px;
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch; } }

.footerH {
  height: 100px; }

.footerbg {
  background-color: #003831;
  padding: 30px; }
  @media (min-width: 641px) and (max-width: 992px) {
    .footerbg {
      padding-bottom: 0; } }
  @media (max-width: 640px) {
    .footerbg {
      padding: 40px 4.8% 0; } }

footer {
  font-family: 'DINPro-Light','Microsoft JhengHei', "微軟正黑體", sans-serif;
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  font-size: 0.75rem;
  color: white;
  font-weight: normal;
  letter-spacing: 0.1em; }
  @media (min-width: 992px) {
    footer {
      display: flex;
      justify-content: space-between; }
      footer .mt5 {
        margin-top: 5px; } }
  @media (max-width: 991px) {
    footer {
      padding: 0px 0px 60px; }
      footer .mt5 {
        text-align: center; } }
  @media (max-width: 640px) {
    footer {
      font-size: 0.875rem; } }
  footer a[href^=tel], footer a {
    color: white; }
    footer a[href^=tel]:hover, footer a:hover {
      color: white; }
  footer .mflex img {
    max-width: 330px; }
  @media (min-width: 992px) {
    footer .mflex {
      display: flex; }
      footer .mflex span {
        border-left: 1px solid white;
        padding-left: 15px;
        margin-left: 15px;
        line-height: 1; }
      footer .mflex span:first-child {
        border: none;
        padding-left: 0px;
        margin-left: 0px; } }
  @media (max-width: 991px) {
    footer .mflex {
      text-align: center; }
      footer .mflex img {
        margin-bottom: 10px;
        max-width: 339px; }
      footer .mflex span {
        margin: 5px 0; } }
  @media (min-width: 992px) {
    footer .info {
      text-align: right; }
      footer .info .mflex {
        justify-content: flex-end; } }
  @media (max-width: 991px) {
    footer .info span {
      display: block;
      line-height: 2; } }
  footer .copyright {
    font-family: "DINPro-Light", sans-serif; }
    @media (max-width: 992px) {
      footer .copyright {
        width: 100%;
        position: absolute;
        bottom: 25px;
        left: 50%;
        transform: translateX(-50%); } }

/*----------------------表單------------------------*/
input[type="tel"],
input[type="text"],
input[type="email"],
input[type="date"],
input[type="time"],
input[type="password"],
select,
textarea {
  -webkit-appearance: none;
  box-shadow: none;
  transition: all 0.5s;
  height: 40px;
  line-height: 1.2;
  width: 100%;
  background-color: white;
  padding: 10px 16px;
  letter-spacing: 1px;
  border: 1px solid rgba(68, 72, 83, 0.5);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px; }
  @media (max-width: 640px) {
    input[type="tel"],
    input[type="text"],
    input[type="email"],
    input[type="date"],
    input[type="time"],
    input[type="password"],
    select,
    textarea {
      font-size: 0.9375rem; } }

textarea {
  height: auto;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px; }

select::-ms-expand {
  display: none; }

::-webkit-input-placeholder {
  font-size: 0.875rem;
  color: #c1c3c3;
  font-weight: normal; }

::-moz-placeholder {
  /* Firefox 19+ */
  font-size: 0.875rem;
  color: #c1c3c3;
  font-weight: normal; }

:-ms-input-placeholder {
  /* IE 10+ */
  font-size: 0.875rem;
  color: #c1c3c3;
  font-weight: normal; }

:-moz-placeholder {
  /* Firefox 18- */
  font-size: 0.875rem;
  color: #c1c3c3;
  font-weight: normal; }

@media (max-width: 640px) {
  ::-webkit-input-placeholder {
    font-size: 1rem; }
  ::-moz-placeholder {
    /* Firefox 19+ */
    font-size: 1rem; }
  :-ms-input-placeholder {
    /* IE 10+ */
    font-size: 1rem; }
  :-moz-placeholder {
    /* Firefox 18- */
    font-size: 1rem; } }

.loginbtn {
  text-align: center;
  margin-top: 20px; }
  .loginbtn a {
    font-size: 1.125rem;
    color: #ffffff;
    font-weight: normal;
    display: inline-block;
    margin: 0 8px; }
  .loginbtn a.active, .loginbtn a:hover {
    color: #005eb8; }

body.lang_tw {
  font-family: 'Microsoft JhengHei', "微軟正黑體", sans-serif; }
  body.lang_tw .en {
    display: none; }
  body.lang_tw .tw {
    display: block; }
  body.lang_tw .service.system .items .txtbox .mtitle {
    font-weight: 700; }
  body.lang_tw header {
    font-weight: 700; }

body.lang_en {
  font-family: 'trebuc_1',  'Microsoft JhengHei', "微軟正黑體", sans-serif;
  letter-spacing: 0.05em; }
  body.lang_en .en {
    display: block; }
  body.lang_en .tw {
    display: none; }
  body.lang_en header {
    font-family: "trebuc_b", "Microsoft JhengHei", "微軟正黑體", sans-serif; }
  body.lang_en header ul.mmenu > li:not(.langubtn) > a {
    font-weight: normal;
    letter-spacing: 0.2em; }
  body.lang_en header ul.subBox a {
    letter-spacing: 0.1em; }
  body.lang_en header ul.mmenu > li:not(.langubtn) a::after {
    height: calc(100% - 0.2em); }
  body.lang_en header ul.mmenu > li:not(.langubtn).hasmenu > a::before {
    top: 76px;
    left: initial;
    transform: translateX(0); }
  @media all and (-ms-high-contrast: none) {
    body.lang_en header ul.mmenu > li:not(.langubtn).hasmenu > a::before {
      left: 2px !important; } }
  @media (min-width: 992px) {
    body.lang_en header .menu_switch span {
      font-size: 0.75rem;
      letter-spacing: 0;
      margin-left: -1px; } }
  @media (max-width: 991px) {
    body.lang_en header .navBox {
      padding-top: 15vh; } }
  body.lang_en .indx .mtitle h2 {
    letter-spacing: 0.2em;
    font-weight: normal;
    font-family: "trebuc_b", sans-serif; }
  body.lang_en .banner h6 {
    letter-spacing: 0.2em;
    font-weight: normal; }
  body.lang_en .btngroup a {
    letter-spacing: 0.05em;
    font-weight: normal; }
    body.lang_en .btngroup a:after {
      width: calc(100% - 0.1em); }
  body.lang_en .btn {
    min-width: 100px; }
  body.lang_en .about .chronology .conclusion {
    letter-spacing: 0.05em; }
  body.lang_en .service.process ul.tab-title a {
    letter-spacing: 0.05em;
    font-weight: normal;
    font-family: "trebuc_b", "Microsoft JhengHei", "微軟正黑體", sans-serif; }
  body.lang_en .service.process ul.tab-title i {
    margin-top: 2px;
    margin-right: 5px;
    font-weight: 700;
    font-family: "trebuc_1", "Microsoft JhengHei", "微軟正黑體", sans-serif; }
  body.lang_en .service.process ul.tab-title span {
    padding-bottom: 2px;
    line-height: 1.8; }
  body.lang_en .service.process .tab-inner h2 {
    letter-spacing: 0.05em; }
  body.lang_en .contact h1,
  body.lang_en .service.craft h2 span,
  body.lang_en .service.process .tab-inner h2 {
    font-weight: normal;
    font-family: "trebuc_b", "Microsoft JhengHei", "微軟正黑體", sans-serif; }
  @media (max-width: 640px) {
    body.lang_en .btngroup {
      justify-content: space-between;
      margin: 30px 4.8% 40px; }
    body.lang_en .btngroup a {
      letter-spacing: 0em; } }

/******************************************/
/*		pages
/******************************************/
body {
  font-size: 0.875rem;
  color: #444853;
  font-weight: normal;
  background-color: #ffffff;
  line-height: 1.5;
  letter-spacing: 0.1em;
  overflow-x: hidden;
  opacity: 0;
  visibility: hidden; }
  body.modal_open {
    height: 100vh;
    overflow: hidden;
    position: fixed; }
  body.fade-in {
    opacity: 1;
    transition: opacity  2s ease;
    visibility: visible; }

#Wapper {
  padding-top: 250px; }

@media (max-width: 991px) {
  #Wapper {
    padding-top: 100px; } }

@media (max-width: 640px) {
  #Wapper {
    padding-top: 60px; } }

.overlay {
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  z-index: 99; }

.modal-content {
  max-width: 1000px;
  width: 90%;
  height: 90%;
  overflow-y: scroll;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  z-index: 999;
  display: none; }

.row {
  max-width: 1200px;
  margin: 0 auto;
  position: relative; }
  @media (max-width: 991px) {
    .row .col-12 {
      padding: 0 4.8%; } }

.rowbox {
  max-width: 1230px;
  padding: 0 15px;
  margin: 0 auto;
  position: relative; }
  @media (max-width: 1200px) {
    .rowbox {
      padding: 0 30px; } }
  @media (max-width: 640px) {
    .rowbox {
      padding: 0 4.8%; } }

.w800 {
  max-width: 830px;
  margin: 0 auto; }
  @media (max-width: 1200px) {
    .w800 {
      max-width: 860px;
      padding: 0 4.8%; }
      .w800 .rowbox {
        padding: 0; } }

.pos-middle, .portfolio.mdetail .more i, .portfolio.mdetail .more i:after {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.bgcover {
  width: 100%;
  height: 0;
  background-size: cover; }

.banner {
  padding-bottom: 19%;
  position: relative;
  background-position: center; }
  .banner h6 {
    font-size: 1.25rem;
    color: white;
    font-weight: 700;
    letter-spacing: 0.55em; }
  @media (max-width: 767px) {
    .banner {
      padding-bottom: 39%; } }

.default_motion, .btngroup a:after, .portfolio .items .bgcolor, .portfolio .items .mtitle:after, .portfolio.mlist .items a *, .portfolio.mlist .items a .mimg:after, .portfolio.mlist .items a .mimg:before, .portfolio.mlist .items a .bgcover:before, .portfolio.mlist .items a .bgcover:after, .service.process ul.tab-title a span {
  transition: all 0.6s; }

.btn {
  height: 25px;
  line-height: 23px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  color: #444853;
  border: 1px solid #444853;
  background-color: white;
  display: inline-block;
  padding: 0 10px;
  text-align: center;
  font-size: 0.75rem; }
  .btn:hover {
    color: #444853; }
  @media (min-width: 641px) {
    .btn:hover {
      background-color: #003831;
      border: 1px solid #003831;
      color: white; } }
  @media (max-width: 640px) {
    .btn {
      height: 40px;
      line-height: 38px;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      border-radius: 10px; } }

.btngroup {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 100px auto; }
  .btngroup a {
    display: inline-block;
    font-size: 1rem;
    color: #444853;
    font-weight: 700;
    font-size: 1.04rem;
    margin: 0 35px;
    letter-spacing: 0.6em; }
    .btngroup a:after {
      width: calc(100% - 0.5em);
      margin-top: 5px;
      content: ' ';
      display: block;
      height: 1px;
      background-color: #003831;
      opacity: 0; }
    .btngroup a.active {
      color: #003831; }
      .btngroup a.active:after {
        opacity: 1; }
  @media (min-width: 641px) {
    .btngroup a:hover {
      color: #003831; }
      .btngroup a:hover:after {
        opacity: 1; } }
  @media (max-width: 640px) {
    .btngroup {
      justify-content: space-around;
      margin: 30px 4.8% 40px; }
      .btngroup a {
        font-size: 0.95rem;
        letter-spacing: 0;
        margin: 0; }
        .btngroup a:after {
          width: 100%; } }

@media (min-width: 992px) {
  .indx#Wapper {
    padding-top: 0; }
  .indx .footerH {
    display: none; } }

.indx #fullpage {
  overflow-x: hidden; }

@media (min-width: 768px) {
  .indx figure {
    overflow: hidden;
    border-bottom-left-radius: 40px;
    border-bottom-right-radius: 40px; }
    .indx figure .bgcover {
      padding-bottom: 66.7%; } }

@media (min-width: 992px) {
  .indx h2 {
    font-size: 1rem;
    color: #003831;
    font-weight: 700;
    -webkit-writing-mode: vertical-rl;
    writing-mode: vertical-rl;
    background: no-repeat url("../svg/indxTitle_lg.svg") 0/43px;
    line-height: 40px;
    width: 40px;
    height: 110px;
    letter-spacing: 0.4em;
    padding-top: 2px; }
  .indx .rowbox {
    display: flex;
    justify-content: space-around;
    width: 100%; }
    .indx .rowbox .mtitle {
      width: 120px; }
    .indx .rowbox .row {
      width: 84%; }
  .indx .more {
    position: absolute;
    left: -100px;
    bottom: 10px; }
    .indx .more .btn {
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      border-radius: 0;
      border: none;
      border-bottom: 1px solid rgba(255, 255, 255, 0);
      background-color: rgba(255, 255, 255, 0);
      padding: 0; }
      .indx .more .btn:hover {
        color: #003831;
        border-bottom: 1px solid #003831; } }

@media (max-width: 991px) {
  .indx h2 {
    font-size: 1.25rem;
    color: #003831;
    font-weight: 700;
    background: no-repeat url("../svg/indxTitle_sm.svg") 0 100%/120px;
    line-height: 40px;
    letter-spacing: 0.2em;
    margin: 40px 0 20px; }
  .indx .more {
    width: 100%;
    text-align: center; }
    .indx .more .btn {
      width: 100px;
      height: 40px;
      line-height: 38px;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      border-radius: 10px; } }

@media (min-width: 767px) and (max-width: 991px) {
  .indx .more .btn {
    background-color: rgba(255, 255, 255, 0); }
    .indx .more .btn:hover {
      background-color: #003831; } }

@media (min-width: 1024px) {
  .indx section .mflex-s {
    display: flex;
    align-items: center;
    height: 100vh; } }

.bannerbg {
  overflow: hidden;
  position: relative;
  background-color: white; }
  .bannerbg .bannerBox {
    position: relative; }
    .bannerbg .bannerBox + #scdown {
      text-align: center;
      margin-top: 20px; }
      .bannerbg .bannerBox + #scdown span {
        cursor: pointer;
        display: inline-block; }
      .bannerbg .bannerBox + #scdown span:before {
        content: ' ';
        display: block;
        top: 0;
        width: 1px;
        height: 35px;
        background-color: #003831;
        margin: 0px auto 5px; }
  @media (min-width: 1300px) {
    .bannerbg {
      height: 100vh;
      padding-top: 250px; }
      .bannerbg.rsizeH {
        padding-top: 180px; }
        .bannerbg.rsizeH .slick-list {
          height: calc(100vh - 280px); }
        .bannerbg.rsizeH .mimg {
          transform: translateY(-15%); } }
  @media (min-width: 1300px) and (-ms-high-contrast: none) {
    .bannerbg {
      padding-top: 200px; } }
  @media (min-height: 820px) {
    .bannerbg.rsizeH .mimg {
      transform: translateY(0%); } }
  .bannerbg .indx-banner .slick-slide {
    padding: 0 10px; }
  .bannerbg .indx-banner .mimg {
    position: relative; }
    .bannerbg .indx-banner .mimg:after {
      content: ' ';
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: #003831;
      opacity: 0.5;
      transition: all 0.8s ease; }
  .bannerbg .indx-banner .slick-center .mimg:after {
    opacity: 0;
    transition: all 0.8s ease;
    transition-delay: 2s; }
  .bannerbg .txt {
    font-size: 2.5rem;
    color: white;
    font-weight: normal;
    position: absolute;
    top: 50%;
    left: 51%;
    transform: translate(-50%, -50%);
    z-index: 1;
    text-shadow: black 0.1em 0.1em 0.2em;
    width: 80%;
    text-align: center;
    font-family: 'trebuc_b', sans-serif; }
  @media (min-width: 768px) {
    .bannerbg .mimg.show-m {
      display: none; } }
  @media (min-width: 992px) {
    .bannerbg .txt.show-m {
      display: none; } }
  @media (max-width: 991px) {
    .bannerbg .txt.show-g {
      display: none; }
    .bannerbg .txt.show-m {
      text-align: center;
      font-size: 2.1875rem;
      line-height: 1; } }
  @media (max-width: 767px) {
    .bannerbg .mimg.show-g {
      display: none; }
    .bannerbg .indx-banner .slick-slide {
      padding: 0;
      width: 100vw; }
    .bannerbg .slick-dots li button:before {
      width: 10px;
      height: 10px; }
    .bannerbg .slick-dots li.slick-center button:before {
      background-color: #003831; }
    .bannerbg .bannerBox + #scdown {
      position: absolute;
      left: 50%;
      bottom: 70px;
      transform: translateX(-50%);
      color: white;
      font-size: 0.75rem; }
      .bannerbg .bannerBox + #scdown span:before {
        background-color: white; } }

.indx-about .mtxt {
  text-align: justify; }

@media (min-width: 992px) {
  .indx-about .rowbox .mtxt {
    width: 41%;
    line-height: 1.6;
    padding-right: 15px; }
  .indx-about .rowbox .mimg {
    width: 50%; } }

@media (max-width: 992px) {
  .indx-about .mimg {
    text-align: center;
    margin-top: 20px; }
  .indx-about .mimg {
    display: none; } }

.indx-service {
  background-color: #e4e4e4; }
  .indx-service figure span {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    line-height: 80px;
    z-index: 5;
    font-family: "DINPro-Light", sans-serif;
    font-size: 1.25rem;
    color: #444853;
    font-weight: normal; }
  .indx-service figure {
    border: 2px solid #003831; }
  .indx-service figcaption {
    background-color: rgba(255, 255, 255, 0.7);
    color: black;
    line-height: 20px;
    padding: 30px 0px;
    text-align: center;
    font-size: 1rem;
    width: 100%;
    z-index: 4;
    position: absolute;
    top: 50%;
    left: -85%;
    transform: translate(0%, -50%);
    transition: left 0.8s cubic-bezier(0.075, 0.82, 0.165, 1); }
  .indx-service a.col-lg-4 {
    padding-right: 5px;
    padding-left: 5px;
    margin-bottom: 10px; }
  .indx-service a:hover figcaption {
    left: 0%; }
  @media (max-width: 992px) and (min-width: 768px) {
    .indx-service {
      padding: 40px 0;
      margin-top: 40px; }
      .indx-service h2 {
        margin-top: 0; }
      .indx-service a.col-lg-4 {
        padding-right: 15px;
        padding-left: 15px;
        margin-bottom: 30px; } }
  @media (max-width: 767px) {
    .indx-service {
      background: rgba(228, 228, 228, 0); }
      .indx-service .mtitle {
        padding: 0 4.8%; }
      .indx-service .rowbox {
        padding: 0; }
      .indx-service .show-md-up {
        display: none; }
      .indx-service .show-sm {
        display: block; }
      .indx-service .items {
        background: no-repeat url("../images/index/service-sm.jpg") center/contain;
        padding-bottom: 125%;
        position: relative; }
      .indx-service ul {
        background-color: white;
        text-align: center;
        width: 90%;
        height: 90%;
        padding: 10px 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        box-shadow: 6px 5px 5px rgba(0, 0, 0, 0.2); }
      .indx-service li {
        font-size: 0.9375rem;
        width: 100%; }
      .indx-service li .btn {
        min-width: 100px; } }

.indx-portfolio a.col-12 {
  padding-right: 5px;
  padding-left: 5px;
  margin-bottom: 10px; }

@media (min-width: 768px) {
  .indx-portfolio figcaption {
    background-color: rgba(255, 255, 255, 0.7);
    color: black;
    line-height: 20px;
    padding: 10px 20px;
    height: 80px;
    font-size: 1rem;
    width: 63%;
    z-index: 4;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0%, -50%); }
    .indx-portfolio figcaption span {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: calc(100% - 40px); }
  .indx-portfolio .bgcover {
    transform: scale(1);
    transition: transform 0.8s cubic-bezier(0.075, 0.82, 0.165, 1);
    transform-origin: top center;
    -moz-transform-origin: top center;
    -o-transform-origin: top center; }
  .indx-portfolio a:hover .bgcover {
    transform: scale(1.2); } }

@media (max-width: 992px) and (min-width: 768px) {
  .indx-portfolio a.col-12 {
    padding-right: 15px;
    padding-left: 15px;
    margin-bottom: 30px; } }

@media (max-width: 767px) {
  .indx-portfolio .bgcover {
    padding-bottom: 52%; }
  .indx-portfolio figcaption {
    margin-top: 12px; }
  .indx-portfolio a.col-12 {
    margin-bottom: 36px;
    padding: 0; } }

.logo-path {
  fill: transparent;
  stroke: #00683C;
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: dash 2s linear forwards,fill-path 1s linear forwards 1.3s; }
  @media all and (-ms-high-contrast: none) {
    .logo-path {
      fill: #00683C;
      opacity: 0;
      animation: fill-arrow 0.8s linear forwards 0.5s; } }

@keyframes dash {
  to {
    stroke-dashoffset: 0; } }

@keyframes fill-path {
  to {
    fill: #00683C; } }

.logo-txt path {
  fill: transparent;
  animation: fill-txt 1s linear forwards 1.3s; }
  @media all and (-ms-high-contrast: none) {
    .logo-txt path {
      fill: #444853;
      opacity: 0;
      animation: fill-arrow 0.8s linear forwards 0.5s; } }

@keyframes fill-txt {
  to {
    fill: #444853; } }

.logo-arrow {
  opacity: 0;
  animation: fill-arrow 0.8s linear forwards 2s; }

@keyframes fill-arrow {
  to {
    opacity: 1; } }

#loading {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: white;
  z-index: 1012;
  overflow-y: scroll;
  overflow-x: hidden; }
  #loading svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  @media (max-width: 640px) {
    #loading svg {
      transform: scale(1.7); } }

@media (min-width: 1600px) {
  canvas {
    height: 30vh !important; } }

@media (min-width: 992px) {
  canvas {
    position: fixed;
    bottom: 0%;
    left: -30%;
    width: 160%;
    height: 25vh;
    opacity: 0;
    transition: opacity 0.6s;
    z-index: -1; }
  canvas.mdown {
    top: 0%;
    bottom: inherit; }
  canvas.alpha {
    opacity: 1; } }

@media (max-width: 991px) {
  canvas {
    display: none; } }

.about {
  background: no-repeat url("../images/line_bg/about_bg.png") top center; }
  @media (max-width: 640px) {
    .about {
      background: inherit; }
      .about .w800 {
        padding: 0 4.8%; }
        .about .w800 .rowbox {
          padding: 0; } }
  .about ul.infolist {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
    .about ul.infolist li {
      width: 50%;
      margin-bottom: 20px; }
    @media (max-width: 640px) {
      .about ul.infolist li {
        margin-bottom: 12px; } }
  .about ul.tableist {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 40px; }
    .about ul.tableist li {
      border: 1px solid white;
      padding: 6px 10px;
      text-align: center; }
    .about ul.tableist li:nth-child(odd) {
      width: 40%;
      background-color: #e6e6e6; }
    .about ul.tableist li:nth-child(even) {
      width: 10%;
      background-color: #dadada; }
    .about ul.tableist li:nth-child(-n+4) {
      background-color: #cdcdcd; }
    @media (max-width: 640px) {
      .about ul.tableist li:nth-child(-n+2) {
        display: none; }
      .about ul.tableist li {
        padding: 6px; }
      .about ul.tableist li:nth-child(odd) {
        width: 78%; }
      .about ul.tableist li:nth-child(even) {
        width: 22%; } }
  .about h4 {
    font-size: 1rem;
    color: #003831;
    margin: 35px 0 12px; }
    @media (max-width: 640px) {
      .about h4 {
        font-size: 0.9375rem;
        margin: 20px 0 6px; } }
  .about .figure_items figure {
    display: flex;
    align-items: center;
    margin-top: 30px; }
    .about .figure_items figure:nth-child(odd) .txtbox {
      background-color: #e6e6e6; }
    .about .figure_items figure:nth-child(even) .txtbox {
      background-color: rgba(168, 193, 161, 0.6);
      color: #003831; }
    .about .figure_items figure:nth-child(even) .mimg {
      order: 2; }
  .about .figure_items .mimg {
    width: 62.5%; }
  .about .figure_items .wm80 {
    width: 80px; }
  .about .figure_items .txtbox {
    width: 37.5%;
    padding: 12px; }
    .about .figure_items .txtbox figcaption {
      border: 2px solid white;
      width: 100%;
      height: 0;
      padding-bottom: calc(80% - 4px);
      text-align: center;
      position: relative; }
      .about .figure_items .txtbox figcaption > div {
        width: 100%; }
  .about .figure_items .bgcover {
    padding-bottom: 66%; }
  @media (max-width: 640px) {
    .about .figure_items .txtbox {
      padding: 5px;
      font-size: 0.75rem;
      letter-spacing: 0; }
      .about .figure_items .txtbox figcaption {
        border-width: 1px; }
    .about .figure_items .wm80 {
      width: 50px; } }
  .about .mb30 {
    margin-bottom: 30px; }
  .about .ideal:after {
    content: ' ';
    display: block;
    width: 100%;
    height: 23px;
    background: url(../images/about/boxshadow.png) bottom/contain;
    position: absolute;
    bottom: -22px;
    left: 0;
    z-index: 1; }
  @media (min-width: 641px) {
    .about .ideal {
      display: flex;
      align-items: stretch;
      justify-content: space-around;
      margin-bottom: 130px; }
      .about .ideal .txt {
        padding: 0 40px 20px 5px;
        min-height: 455px;
        flex: 0 0 46%;
        text-align: justify; }
      .about .ideal .mimg {
        box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
        background-color: white;
        flex: 0 0 54%;
        order: 2; }
        .about .ideal .mimg .pos {
          width: 90%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%); } }
  @media (max-width: 640px) {
    .about .ideal {
      padding: 0; }
      .about .ideal .txt {
        padding: 0 4.8%;
        margin-top: 30px; } }
  @media (max-width: 991px) and (min-width: 641px) {
    .about .chronology {
      padding: 0 20px;
      max-width: 680px; } }
  @media (min-width: 992px) {
    .about .chronology .figure {
      position: relative;
      display: flex;
      justify-content: space-around;
      align-items: center; }
      .about .chronology .figure .mimg, .about .chronology .figure .figcaption {
        width: calc(50% - 50px);
        flex: 0 0 calc(50% - 50px); }
      .about .chronology .figure .myear {
        max-width: 100px;
        flex: 0 0 100px;
        position: relative;
        font-size: 1rem; }
      .about .chronology .figure:nth-child(odd) .myear {
        order: 2; }
      .about .chronology .figure:nth-child(odd) .mimg {
        order: 1;
        padding-right: 2.5%; }
      .about .chronology .figure:nth-child(odd) .figcaption {
        order: 3;
        padding-left: 2.5%;
        text-align: left; }
      .about .chronology .figure:nth-child(even) .myear {
        order: 2; }
      .about .chronology .figure:nth-child(even) .mimg {
        order: 3;
        padding-left: 2.5%; }
      .about .chronology .figure:nth-child(even) .figcaption {
        order: 1;
        padding-right: 2.5%;
        text-align: right; }
      .about .chronology .figure:nth-child(even) .figcaption > div {
        float: right; } }
  .about .chronology .figure:nth-child(even) .circle {
    background-color: #003831;
    color: white; }
  .about .chronology .line {
    width: 1px;
    height: 85px;
    margin: 0 auto;
    background-color: #003831; }
    @media (min-width: 992px) {
      .about .chronology .line {
        position: absolute;
        left: 50%;
        top: 0;
        height: 100%;
        z-index: -1; } }
  .about .chronology .circle {
    width: 70px;
    height: 70px;
    line-height: 70px;
    text-align: center;
    border: 1px solid #003831;
    -webkit-border-radius: 99em;
    -moz-border-radius: 99em;
    border-radius: 99em;
    background-color: white;
    color: #003831;
    margin: 0 auto 15px;
    position: relative; }
    @media (min-width: 992px) {
      .about .chronology .circle {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); } }
    .about .chronology .circle:after {
      content: ' ';
      display: block;
      width: 12px;
      height: 12px;
      border-left: 1px solid #003831;
      border-bottom: 1px solid #003831;
      position: absolute;
      top: calc(50% - 43px);
      left: 50%;
      transform: translate(-50%, -50%) rotate(-45deg); }
  .about .chronology .bgcover {
    padding-bottom: 48%; }
  .about .chronology .figcaption {
    padding-top: 10px;
    padding-bottom: 25px;
    text-align: center; }
    .about .chronology .figcaption span {
      font-size: 1rem;
      color: #003831;
      font-weight: 700; }
  .about .chronology .conclusion {
    text-align: center;
    padding-top: 150px;
    position: relative;
    font-size: 1.125rem;
    color: #003831;
    font-weight: normal;
    letter-spacing: 0.2em; }
    .about .chronology .conclusion .line {
      height: 110px; }
      .about .chronology .conclusion .line:after {
        content: ' ';
        display: block;
        width: 12px;
        height: 12px;
        border-left: 1px solid #003831;
        border-bottom: 1px solid #003831;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 0%) rotate(-45deg); }
    @media (max-width: 991px) {
      .about .chronology .conclusion {
        padding-top: 0;
        font-size: 0.9375rem; }
        .about .chronology .conclusion .line {
          height: 85px;
          margin-bottom: 15px;
          position: relative; } }

@media (max-width: 1200px) {
  .portfolio .row {
    padding: 0 3.8%; }
    .portfolio .row .items {
      padding: 0 1%; } }

.portfolio .items {
  margin-bottom: 36px;
  padding: 0 0.6667%; }
  .portfolio .items .bgcover {
    padding-bottom: 51%; }
  .portfolio .items .bgcolor {
    background-color: rgba(0, 56, 49, 0.5);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0; }
  .portfolio .items .mtitle {
    color: #444853;
    display: inline-block;
    margin-top: 12px; }
    .portfolio .items .mtitle span {
      color: #8f9196;
      font-size: 0.75rem;
      margin-left: 10px;
      font-weight: normal; }
    .portfolio .items .mtitle:after {
      content: ' ';
      display: block;
      width: 100%;
      height: 1px;
      background-color: #003831;
      margin-top: 2px;
      opacity: 0; }
  @media (min-width: 641px) {
    .portfolio .items a:hover .bgcolor, .portfolio .items a:hover .more {
      opacity: 1; }
    .portfolio .items a:hover .mtitle:after {
      opacity: 1; }
    .portfolio .items a:hover .mtitle {
      color: #003831;
      font-weight: 700; }
      .portfolio .items a:hover .mtitle span {
        color: #003831; } }

.portfolio.mlist .footerH {
  display: none; }

.portfolio.mlist .bgimg {
  background: no-repeat url("../images/line_bg/protfolio_list_bg.png") center top;
  padding-top: 150px;
  padding-bottom: 100px; }

.portfolio.mlist .items a * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

.portfolio.mlist .items a .bgcover:before, .portfolio.mlist .items a .bgcover:after {
  width: 2px;
  height: 0; }

.portfolio.mlist .items a .bgcover:before {
  right: 0;
  top: 0; }

.portfolio.mlist .items a .bgcover:after {
  left: 0;
  bottom: 0; }

.portfolio.mlist .items a .mimg:before, .portfolio.mlist .items a .mimg:after {
  height: 2px;
  width: 0%; }

.portfolio.mlist .items a .mimg:before {
  top: 0;
  left: 0; }

.portfolio.mlist .items a .mimg:after {
  bottom: 0;
  right: 0; }

.portfolio.mlist .items a .mimg:after, .portfolio.mlist .items a .mimg:before, .portfolio.mlist .items a .bgcover:before, .portfolio.mlist .items a .bgcover:after {
  position: absolute;
  content: '';
  background-color: #003831;
  z-index: 1; }

@media (min-width: 641px) {
  .portfolio.mlist .items a:hover .bgcover:before, .portfolio.mlist .items a:hover .bgcover:after {
    height: 100%; }
  .portfolio.mlist .items a:hover .mimg:after, .portfolio.mlist .items a:hover .mimg:before {
    width: 100%; } }

@media (max-width: 767px) {
  .portfolio.mlist .bgimg {
    padding-top: 36px;
    background: initial; } }

.portfolio.mdetail {
  background: no-repeat url("../images/line_bg/protfolio_detail_bg.png") center top; }
  .portfolio.mdetail h1 {
    font-size: 1.25rem;
    color: #003831;
    font-weight: normal;
    margin-bottom: 60px; }
  .portfolio.mdetail .photo {
    margin-bottom: 76px; }
    .portfolio.mdetail .photo .bgcover {
      padding-bottom: 40%; }
    @media (max-width: 640px) {
      .portfolio.mdetail .photo .bgcover {
        padding-bottom: 85%; } }
  .portfolio.mdetail ul li div:nth-child(1) {
    font-size: 1rem;
    margin-bottom: 5px; }
  @media (min-width: 641px) {
    .portfolio.mdetail ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: 80px; }
      .portfolio.mdetail ul li {
        width: 50%;
        margin-bottom: 25px; } }
  @media (max-width: 640px) {
    .portfolio.mdetail ul {
      margin-bottom: 40px; }
      .portfolio.mdetail ul li {
        margin-bottom: 12px; } }
  .portfolio.mdetail .more {
    width: 32px;
    height: 32px;
    display: block;
    border: 1px solid white;
    -webkit-border-radius: 99em;
    -moz-border-radius: 99em;
    border-radius: 99em;
    opacity: 0; }
    .portfolio.mdetail .more i, .portfolio.mdetail .more i:after {
      display: block;
      background-color: white; }
    .portfolio.mdetail .more i {
      width: 1px;
      height: 16px; }
    .portfolio.mdetail .more i:after {
      content: ' ';
      width: 16px;
      height: 1px; }
  @media (max-width: 640px) {
    .portfolio.mdetail {
      background: initial; }
      .portfolio.mdetail .photo {
        margin-bottom: 36px; }
      .portfolio.mdetail h1 {
        margin-bottom: 20px; } }

.portfolio .btngroup-detail {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  max-width: 330px;
  padding: 35px 0 25px;
  border-top: 1px solid #8f9196;
  border-bottom: 1px solid #8f9196;
  margin: 0 auto; }
  @media (min-width: 640px) {
    .portfolio .btngroup-detail {
      margin: 70px auto 0; } }
  .portfolio .btngroup-detail a {
    display: block;
    font-size: 0.75rem;
    color: #444853;
    margin: 0; }
    .portfolio .btngroup-detail a:before {
      content: ' ';
      display: block; }
    .portfolio .btngroup-detail a span {
      margin-top: 12px;
      text-align: center; }
  .portfolio .btngroup-detail .prev:before {
    width: 100%;
    height: 14px;
    background: no-repeat url("../images/prev.png") 0 0/18px;
    transform: translateY(-6px); }
  .portfolio .btngroup-detail .next:before {
    width: 100%;
    height: 14px;
    background: no-repeat url("../images/next.png") 100% 0/18px;
    transform: translateY(-6px); }
  .portfolio .btngroup-detail .back:before {
    width: 40px;
    height: 25px;
    background: no-repeat url("../images/icon_list.png") 0 0/40px;
    margin: 0 auto; }
  .portfolio .btngroup-detail .nopage {
    opacity: 0;
    z-index: -1; }
  @media (min-width: 641px) {
    .portfolio .btngroup-detail a:hover {
      color: #003831; }
    .portfolio .btngroup-detail .prev:hover:before {
      background-position: 0 -14px; }
    .portfolio .btngroup-detail .next:hover:before {
      background-position: 100% -14px; }
    .portfolio .btngroup-detail .back:hover:before {
      background-position: 0 -25px; } }

@media (max-width: 860px) {
  .fancybox-image, .fancybox-spaceball {
    left: 30px;
    width: calc(100% - 60px); } }

.fancybox-content a.close {
  border: 1px solid white;
  -webkit-border-radius: 99em;
  -moz-border-radius: 99em;
  border-radius: 99em;
  z-index: 99993;
  position: absolute;
  display: block;
  width: 31px;
  height: 31px;
  top: -40px;
  right: -40px; }
  .fancybox-content a.close:before, .fancybox-content a.close:after {
    position: absolute;
    left: 14px;
    top: 9px;
    content: ' ';
    height: 12px;
    width: 1px;
    background-color: white; }
  .fancybox-content a.close:before {
    transform: rotate(45deg); }
  .fancybox-content a.close:after {
    transform: rotate(-45deg); }
  @media (max-width: 992px) {
    .fancybox-content a.close {
      right: 50%;
      transform: translateX(50%);
      top: -50px; } }

.contact {
  background: no-repeat url("../images/line_bg/contact_bg.png") bottom center; }
  .contact h1 {
    font-size: 1.25rem;
    color: #003831;
    font-weight: 700;
    margin-bottom: 24px;
    letter-spacing: 0.2em; }
  .contact .info {
    margin-top: 140px;
    font-size: 0.875rem;
    color: #444853;
    font-weight: normal; }
    .contact .info ul li {
      margin-bottom: 5px;
      display: flex; }
    .contact .info ul span {
      max-width: 70px;
      flex: 0 0 70px; }
    .contact .info .txt {
      border-top: 1px solid #444853;
      border-bottom: 1px solid #444853;
      padding: 12px 10px;
      text-align: center;
      margin-top: 40px; }
    .contact .info #map {
      height: 180px;
      margin-top: 20px; }
    @media (min-width: 768px) {
      .contact .info .mflex {
        display: flex;
        justify-content: space-between;
        align-items: flex-start; }
        .contact .info .mflex > div {
          width: 50%; }
      .contact .info #map {
        max-width: 370px;
        margin-top: 0px; } }
    @media (max-width: 640px) {
      .contact .info {
        background: initial;
        margin-top: 30px; }
        .contact .info span {
          display: block; }
        .contact .info ul li {
          margin-bottom: 20px;
          display: block; }
        .contact .info .txt {
          padding: 10px;
          line-height: 1.8;
          margin-top: 30px; } }
  .contact .mark {
    font-size: 0.75rem;
    color: #ff1e23;
    font-weight: normal;
    height: 35px;
    padding-top: 5px;
    padding-left: 16px; }
    .contact .mark i {
      transform: scale(0.7);
      margin-right: 5px;
      display: inline-block; }
  .contact form textarea {
    height: 200px; }
  .contact form .btn._send {
    margin: 15px auto 0;
    width: 145px; }
  @media (min-width: 641px) {
    .contact form {
      display: flex;
      justify-content: space-around;
      align-items: center;
      flex-wrap: wrap;
      margin-top: 10px; }
      .contact form > div {
        width: 50%; }
        .contact form > div:nth-child(even) {
          padding-left: 1.25%; }
        .contact form > div:nth-child(odd) {
          padding-right: 1.25%; }
        .contact form > div.w100 {
          width: 100%;
          padding: 0; }
      .contact form .btn._send {
        margin: 35px auto 0;
        width: 80px; } }

@media (max-width: 640px) {
  .service {
    background: inherit !important; } }

.service.craft {
  background: no-repeat url("../images/line_bg/service_bg.png") bottom center; }
  .service.craft h2 {
    margin: 24px 0 36px; }
    .service.craft h2 span {
      display: block;
      font-size: 0.875rem;
      color: #444853;
      font-weight: 700; }
  .service.craft ul {
    margin-bottom: 40px; }
    .service.craft ul li {
      margin-bottom: 5px;
      display: flex; }
    .service.craft ul span {
      max-width: 70px;
      flex: 0 0 70px; }
    @media (max-width: 640px) {
      .service.craft ul li {
        display: block;
        margin-bottom: 12px; }
      .service.craft ul span {
        display: block;
        max-width: 100%;
        flex: initial; } }
  .service.craft .map {
    margin: 0 auto; }
    .service.craft .map iframe {
      width: 100%; }
    @media (max-width: 640px) {
      .service.craft .map iframe {
        height: 240px; } }
  @media (min-width: 991px) {
    .service.craft .more {
      position: absolute;
      bottom: 0;
      left: 15px; } }
  @media (max-width: 991px) {
    .service.craft .more {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin-top: 50px; } }
  @media (max-width: 640px) {
    .service.craft .mlogo {
      width: 160px; } }

.service.process {
  background: no-repeat url("../images/line_bg/service_bg.png") top center; }
  .service.process ul.tab-title {
    margin-bottom: 30px; }
    .service.process ul.tab-title li {
      margin-bottom: 22px; }
    .service.process ul.tab-title a {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      font-size: 1rem;
      color: #444853;
      font-weight: 700; }
      .service.process ul.tab-title a span {
        border-bottom: 1px solid rgba(0, 56, 49, 0);
        line-height: 2;
        padding-bottom: 3px; }
      .service.process ul.tab-title a div {
        display: inline-block; }
      .service.process ul.tab-title a i {
        font-family: "trebuc_1", sans-serif;
        margin-top: 5px;
        margin-right: 3px;
        display: inline-block; }
    @media (min-width: 641px) {
      .service.process ul.tab-title a:hover {
        color: #003831; }
        .service.process ul.tab-title a:hover span {
          border-bottom: 1px solid #003831; }
      .service.process ul.tab-title li.active a {
        color: #003831; }
        .service.process ul.tab-title li.active a span {
          border-bottom: 1px solid #003831; } }
    @media (max-width: 640px) {
      .service.process ul.tab-title li {
        margin-bottom: 0px; }
      .service.process ul.tab-title li a {
        font-size: 0.9375rem;
        padding: 4px 4.8% 4px 5.3%;
        display: block; }
        .service.process ul.tab-title li a:after {
          display: none; }
        .service.process ul.tab-title li a i {
          letter-spacing: 0.1em;
          margin-right: 10px; }
      .service.process ul.tab-title li.active a {
        color: white;
        background-color: #003831;
        display: block; }
      .service.process ul.tab-title li br {
        display: none; } }
  .service.process .tab-inner {
    margin-bottom: 20px; }
    .service.process .tab-inner h2 {
      font-size: 1rem;
      color: #003831;
      font-weight: 700;
      font-family: "trebuc_1", "Microsoft JhengHei", "微軟正黑體", sans-serif;
      margin-bottom: 24px; }
    .service.process .tab-inner .txt img {
      margin-bottom: 20px; }
    @media (max-width: 640px) {
      .service.process .tab-inner h2 {
        font-size: 0.9375rem;
        margin-bottom: 12px; }
      .service.process .tab-inner .txt img {
        margin-bottom: 10px; } }
  @media (max-width: 640px) {
    .service.process .col-12.pd0 {
      padding: 0; } }

.service.system {
  background: no-repeat url("../images/line_bg/servicesystem_bg.png") top center; }
  .service.system .items {
    position: relative;
    z-index: 1; }
    .service.system .items .mflex {
      width: 100%;
      margin-bottom: 20px;
      position: relative; }
      .service.system .items .mflex:nth-child(odd) {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end; }
        .service.system .items .mflex:nth-child(odd):after {
          left: 0; }
      .service.system .items .mflex:nth-child(even) {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start; }
        .service.system .items .mflex:nth-child(even):after {
          right: 0; }
        .service.system .items .mflex:nth-child(even) .txtbox {
          margin-left: 10px;
          margin-right: 0;
          order: 2; }
      .service.system .items .mflex:after {
        content: ' ';
        display: block;
        width: 0%;
        height: 100%;
        background-color: #003831;
        position: absolute;
        top: 0;
        z-index: -1;
        transition: all 1s cubic-bezier(0.645, 0.045, 0.355, 1); }
      @media (min-width: 992px) {
        .service.system .items .mflex:nth-child(odd) {
          padding-left: 20px; }
          .service.system .items .mflex:nth-child(odd) .txtbox {
            margin-right: 35px;
            margin-left: calc(3% - 35px); }
        .service.system .items .mflex:nth-child(even) {
          padding-right: 20px; }
          .service.system .items .mflex:nth-child(even) .txtbox {
            margin-left: 35px;
            margin-right: calc(3% - 35px); }
        .service.system .items .mflex:hover:after {
          width: 80%; }
        .service.system .items .mflex:hover a.txtbox {
          background-color: rgba(230, 230, 230, 0);
          color: white; }
          .service.system .items .mflex:hover a.txtbox .more:after {
            background-color: white; }
          .service.system .items .mflex:hover a.txtbox .mtitle {
            color: white; } }
      @media (max-width: 991px) {
        .service.system .items .mflex:nth-child(odd) .txtbox {
          order: 2; }
        .service.system .items .mflex:nth-child(even) .txtbox {
          margin-left: 0px; } }
    @media (max-width: 991px) {
      .service.system .items {
        max-width: 680px;
        margin: 0 auto;
        padding: 0 20px; } }
    @media (max-width: 640px) {
      .service.system .items {
        padding: 0 4.8%; } }
    .service.system .items .rowbox {
      width: 100%;
      display: flex;
      margin: 0;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
      padding: 20px 0; }
      @media (min-width: 1301px) {
        .service.system .items .rowbox {
          max-width: 80%; } }
      @media (max-width: 640px) {
        .service.system .items .rowbox {
          padding: 0; } }
    .service.system .items a.txtbox {
      background-color: #e6e6e6;
      color: #444853;
      padding: 10px;
      width: 20%;
      margin-right: 2%;
      transition-delay: 0.3s; }
      .service.system .items a.txtbox .bdr {
        border: 2px solid white;
        width: 100%;
        height: 100%;
        position: relative; }
      .service.system .items a.txtbox p {
        margin-top: 15%;
        overflow: hidden; }
      .service.system .items a.txtbox .mtitle {
        color: #003831;
        font-size: 1rem;
        margin-bottom: 5px;
        display: block;
        transition: all 0.6s;
        transition-delay: 0.3s; }
      .service.system .items a.txtbox .more {
        font-size: 0.75rem;
        padding: 10px 0px;
        display: inline-block;
        position: relative; }
        .service.system .items a.txtbox .more:after {
          content: ' ';
          display: inline-block;
          width: 100%;
          height: 1px;
          background-color: #e6e6e6;
          transition: all 0.6s;
          transition-delay: 0.3s;
          position: absolute;
          bottom: 5px;
          left: 0; }
      @media (min-width: 992px) {
        .service.system .items a.txtbox p {
          height: calc(4.5em + 26px); }
        .service.system .items a.txtbox .bdr {
          height: 0;
          padding: 0 15px;
          padding-bottom: calc(100% - 3px); }
        .service.system .items a.txtbox .more {
          position: absolute;
          bottom: 16px; } }
      @media (max-width: 991px) {
        .service.system .items a.txtbox {
          flex: 0 0 100%;
          margin: 4px 0 0; }
          .service.system .items a.txtbox p {
            margin-top: 0;
            margin: 0 0 45px; }
          .service.system .items a.txtbox p span:nth-child(2) {
            height: 4.5em;
            overflow: hidden;
            display: block; }
          .service.system .items a.txtbox .bdr {
            padding: 15px; }
          .service.system .items a.txtbox .more {
            border: 1px solid #003831;
            -webkit-border-radius: 10px;
            -moz-border-radius: 10px;
            border-radius: 10px;
            max-width: 100px;
            width: 100%;
            height: 40px;
            line-height: 40px;
            padding: 0;
            text-align: center;
            color: #003831;
            position: absolute;
            bottom: 10px;
            margin: 0;
            right: 10px; } }
    .service.system .items .imgbox {
      width: 77%;
      display: flex; }
      .service.system .items .imgbox .bgcover {
        padding-bottom: 62%; }
      .service.system .items .imgbox .mimg {
        width: calc((100% - 4px) / 2); }
        .service.system .items .imgbox .mimg:nth-child(1) {
          margin-right: 2px; }
        .service.system .items .imgbox .mimg:nth-child(2) {
          margin-left: 2px; }
      @media (max-width: 991px) {
        .service.system .items .imgbox {
          flex: 0 0 100%; } }
