//語系
body.lang_tw{
    font-family: 'Microsoft JhengHei', "微軟正黑體", sans-serif;
    .en{
        display: none;
    }
    .tw{
        display: block;
    }
    .service.system .items .txtbox .mtitle{
        font-weight: 700;
    }
    header{
        font-weight: 700;
    }
}
body.lang_en{    
    font-family: 'trebuc_1',  'Microsoft JhengHei', "微軟正黑體", sans-serif;
    letter-spacing: 0.05em;
    .en{
        display: block;
    }
    .tw{
        display: none;
    }
    //header
    header{
        font-family:$mfontmixB;
    }
    header ul.mmenu > li:not(.langubtn) > a{
        font-weight: normal;
        letter-spacing:0.2em;
    }
    header:not(:root:link){
        
    }
    header ul.subBox a{
        letter-spacing: 0.1em;
    }
    // header ul.mmenu > li:not(.langubtn){
    //     height: 120px;
    // }
    header ul.mmenu > li:not(.langubtn) a::after{
        height: calc(100% - 0.2em);
    }
    header ul.mmenu > li:not(.langubtn).hasmenu > a::before{
        top:76px;
        left:initial;
        transform: translateX(0);
    }
    @media all and (-ms-high-contrast:none){
        header ul.mmenu > li:not(.langubtn).hasmenu > a::before{
            left:2px !important;
        }
    }
    // header ul.mmenu li.hasmenu:nth-child(1) > a:before{
    //     bottom:-46px;
    // }
    // header ul.mmenu li.hasmenu:nth-child(2) > a:before{
    //     bottom:-27px;
    // }
    @media (min-width:992px){
        header .menu_switch span{
            font-size: pxToEm(12);
            letter-spacing: 0;
            margin-left: -1px;
        }
    }
    @media (max-width: 991px){
        header .navBox{
            padding-top: 15vh;
        }
    }
    .indx{
        .mtitle h2{
            letter-spacing:0.2em;
            font-weight: normal;
            font-family: $trebucB;
        }
    }
    .banner h6{
        letter-spacing: 0.2em;
        font-weight: normal;
    }
    .btngroup a{
        letter-spacing: 0.05em;
        font-weight: normal;
        &:after{
            width:calc(100% - 0.1em);
        }
    }
    .btn{
        min-width: 100px;
    }
    .about .chronology .conclusion{
        letter-spacing: 0.05em;
    }
    .service.process ul.tab-title{
        a{
            letter-spacing: 0.05em;
            font-weight: normal;
            font-family: $mfontmixB;
        }
        i{
            margin-top: 2px;
            margin-right: 5px;
            font-weight: 700;
            font-family: $mfontmix;
        }
        span{
            padding-bottom: 2px;
            line-height: 1.8;
        }
    }
    .service.process .tab-inner h2{
        letter-spacing: 0.05em;
    }
    .contact h1,
    .service.craft h2 span,
    .service.process .tab-inner h2{
        font-weight: normal;
        font-family: $mfontmixB;
    }
    @media (max-width:640px){
        
        .btngroup{
            justify-content: space-between;
            margin:30px 4.8% 40px;
        }
        .btngroup a{
            letter-spacing: 0em;
        }
    }
}