.indx{
    @media (min-width:992px){
        &#Wapper{
            padding-top: 0;
        }
        .footerH{
            display: none;
        }
    }
    
    #fullpage{
        overflow-x: hidden;
    }
    @media (min-width:768px){
        figure{
            overflow: hidden;
            border-bottom-left-radius: 40px;
            border-bottom-right-radius: 40px;        
            .bgcover{
                padding-bottom: 66.7%;
            }
        }          
    }
    @media (min-width:992px){    
        h2{
            @include font-color(pxToEm(16),$green,700);
            -webkit-writing-mode: vertical-rl;
            writing-mode: vertical-rl;
            background: no-repeat url('../svg/indxTitle_lg.svg') 0 / 43px;
            line-height: 40px;
            width: 40px;
            height: 110px;
            letter-spacing: 0.4em;
            padding-top: 2px;
        }
        .rowbox{
            display:flex;
            justify-content: space-around;        
            width:100%;
            .mtitle{
                width:120px;
            }
            .row{
                width:84%;
            } 
        }
        .more{     
            position: absolute;
            left:-100px;
            bottom:10px;      
            .btn{
                @include border(0);
                border:none;
                border-bottom: 1px solid rgba(white,0);
                background-color: rgba(white,0);
                padding:0;
                &:hover{
                    color:$green;
                    border-bottom: 1px solid $green;
                }
            }
        }
        
    }
    
    @media (max-width:991px){
        h2{
            @include font-color(pxToEm(20),$green,700);
            background: no-repeat url('../svg/indxTitle_sm.svg') 0 100%/120px;
            line-height: 40px;
            letter-spacing: 0.2em;
            margin:40px 0 20px;
        }
        .more{
            width:100%;
            text-align: center;
            .btn{
               
                width:100px;
                height: 40px;
                line-height: 38px;
                @include border(10px);
            } 
        }
    }
    @media (min-width:767px) and (max-width:991px){
        .more .btn{
            background-color: rgba(white,0);
            &:hover{
                background-color: rgba($green,1);
            }
        }
    }

    @media  (min-width:1024px){
        section .mflex-s{
            display: flex;
            // justify-content: center;
            align-items: center;
            height: 100vh;
            // width: 100vw;
        }
        section .rowbox{
            // height: 100vh;
            // padding: 5% 0;
            // align-items: center;
            // display: flex;
            // justify-content: center;
            // align-items: center;
        }
    }
    
         
}
.bannerbg{
    overflow: hidden;
    position: relative;
    background-color: white;
    .bannerBox{
        position: relative;
        + #scdown{
            text-align: center;
            margin-top: 20px;
            span{
                cursor: pointer;
                display: inline-block;
            }
            span:before{
                content:' ';
                display: block;
                top:0;
                width:1px;
                height: 35px;
                background-color: $green;
                margin:0px auto 5px;
            }
        }
    }
    @media (min-width:1300px){
        height: 100vh;
        padding-top: 250px;
        &.rsizeH {
            padding-top: 180px;
            // .bannerBox{
                
            //     overflow: hidden;
            // }
            .slick-list{
                // transform: translateY(-50%);

                height: calc(100vh - 280px);
            }
            .mimg{
                transform: translateY(-15%);
            }
        }
    }
    @media (min-width:1300px) and (-ms-high-contrast:none){
        padding-top: 200px;
    }
    @media (min-height:820px){
        &.rsizeH {
            .mimg{
                transform: translateY(0%);
            }
        }
    }
    .indx-banner{
        .slick-slide{
            padding:0 10px;
        }
        .mimg{
            // width:1200px;
            position: relative;
            &:after{
                content:' ';
                display: block;
                width:100%;
                height:100%;
                position: absolute;
                top:0;
                left:0;
                background: $green;
                opacity: 0.5;
                transition: all 0.8s ease ;
            }
        }
        .slick-center .mimg:after{
            opacity: 0;
            transition: all 0.8s ease ;
            transition-delay:2s;
        }        
    }
    .txt{
        @include font-color(pxToEm(40),white);
        position: absolute;
        top:50%;
        left:51%;
        transform: translate(-50%,-50%);
        z-index: 1;
        text-shadow: black 0.1em 0.1em 0.2em;
        width: 80%;
        text-align: center;
        font-family: 'trebuc_b', sans-serif;
    }
    @media (min-width:768px){
        .mimg.show-m{
            display: none;
        }
    }
    
    @media (min-width:992px){
        // padding-top: 215px;
        // height: calc(85vh - 215px);
        .txt.show-m{
            display: none;
        }
    }
    @media (max-width:991px){
        .txt.show-g{
            display: none;
        }
        .txt.show-m{
            text-align: center;
            font-size: pxToEm(35);
            line-height: 1;
        }        
    }
    @media (max-width:767px){
        .mimg.show-g{
            display: none;
        }
        .indx-banner{
            .slick-slide{
                padding:0;
                width:100vw;
            }
        }
        .slick-dots li button:before{
            width:10px;
            height: 10px;
        }
        
        .slick-dots li.slick-center button:before{
            background-color:$green;
        }
        .bannerBox + #scdown{
            position: absolute;
            left:50%;
            bottom:70px;
            transform: translateX(-50%);
            color:white;
            font-size: pxToEm(12);
            span:before{
                background-color: white;
            }
        }
    }
}

.indx-about{
    .mtxt{
        text-align: justify;
    }
    @media (min-width:992px){
        .rowbox{     
            .mtxt{
                width: 41%;
                line-height: 1.6;
                padding-right: 15px;
            }
            .mimg{
                width:50%;
            }
        }
    }
    @media (max-width:992px){
        .mimg{
            text-align: center;
            margin-top: 20px;
        }
        .mimg{
            display: none;
        }
    }
    @media (max-width:767px){
       
        
    }
}
.indx-service{
    background-color:#e4e4e4; 
    
    figure span{
        position: absolute;
        left:10px;
        top:50%;
        transform: translateY(-50%);
        line-height: 80px;
        z-index: 5;
        font-family: $DINPro;
        @include font-color(pxToEm(20),#444853);
    }    
    figure{
        border:2px solid $green;
    }   
    figcaption{
        background-color:rgba($white,0.7); 
        color:black;
        line-height: 20px;
        padding:30px 0px;
        text-align: center;
        font-size: pxToEm(16);
        width:100%;
        z-index: 4;
        position: absolute;
        top:50%;
        left:-85%;
        transform: translate(0%,-50%);
        transition: left 0.8s  cubic-bezier(0.075, 0.82, 0.165, 1);
        
    }
    a.col-lg-4{
        padding-right:5px;
        padding-left: 5px;
        margin-bottom: 10px;
    }
    a:hover{
        figcaption{
            left:0%;
        }
    }
    @media (max-width:992px) and (min-width:768px){
        padding:40px 0;
        margin-top: 40px;
        h2{
            margin-top: 0;
        }
        a.col-lg-4{
            padding-right:15px;
            padding-left: 15px;
            margin-bottom: 30px;
        }
    }
    @media (max-width:767px){
        background: rgba(#e4e4e4,0);
        .mtitle{
            padding:0 4.8%;
        }
        .rowbox{
            padding:0;
        }
        .show-md-up{
            display: none;
        }
        .show-sm{
            display: block;
        }
        .items{
            background:no-repeat url('../images/index/service-sm.jpg') center / contain;
            padding-bottom: 125%;
            position: relative;
        }
        ul{
            background-color: white;
            text-align: center;
            width:90%;
            height: 90%;
            padding:10px 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            box-shadow: 6px 5px 5px rgba(0,0,0,.2);
        }
        li{
            font-size: pxToEm(15);
            width: 100%;
        }
        li .btn{
            min-width: 100px;
        }
        
        
    }
}
.indx-portfolio{
      
    
    a.col-12{
        padding-right:5px;
        padding-left: 5px;
        margin-bottom: 10px;
        
    }
    // @media (min-width:992px){
    //     canvas{
    //         position: absolute;
    //         top:0%;
    //         left:-30%;
    //         width:160%;
    //         height: 25vh;
    //     }
    // }
    @media (min-width:768px){
        figcaption{
            background-color:rgba($white,0.7); 
            color:black;
            line-height: 20px;
            padding:10px 20px;
            height: 80px;
            font-size: pxToEm(16);
            width:63%;
            z-index: 4;
            position: absolute;
            top:50%;
            left:0;
            transform: translate(0%,-50%);
            span{
                position: absolute;
                top:50%;
                transform: translateY(-50%);
                width:calc(100% - 40px);
            }
        }
        .bgcover{
            transform: scale(1);
            transition: transform 0.8s  cubic-bezier(0.075, 0.82, 0.165, 1);
            transform-origin:top center;
            -moz-transform-origin: top center;
            -o-transform-origin: top center;    
        }
        a:hover{
            .bgcover{
                transform: scale(1.2);
            }
        }
    }
    @media (max-width:992px) and (min-width:768px){
        a.col-12{
            padding-right:15px;
            padding-left: 15px;
            margin-bottom: 30px;
            
        }
        // canvas{
        //     display: none;
        // }
    }
    @media (max-width:767px){
        .bgcover{
            padding-bottom: 52%;
        }
        figcaption{
            margin-top: 12px;
        }
        a.col-12{
            margin-bottom: 36px;
            padding:0;
        }
        // canvas{
        //     display: none;
        // }
    }
    
}

//loading
.logo-path{
    
    fill:transparent;
    stroke:#00683C;
    stroke-dasharray: 1000;
    stroke-dashoffset: 1000;
    animation: dash 2s linear forwards,fill-path 1s linear forwards 1.3s; 
    @media all and (-ms-high-contrast:none){
        fill:#00683C;
        opacity:0;
        animation:fill-arrow 0.8s linear forwards 0.5s;  
    }
}
@keyframes dash {
    to {
        stroke-dashoffset: 0;
    }
}
@keyframes fill-path {
    to {
        fill:#00683C;
    }
}
.logo-txt path{    
    fill:transparent;
    animation:fill-txt 1s linear forwards 1.3s;
    @media all and (-ms-high-contrast:none){
        fill:#444853;
        opacity:0;
        animation:fill-arrow 0.8s linear forwards 0.5s; 
    }
}
@keyframes fill-txt {
    to {
        fill:#444853;
    }
}
.logo-arrow{
    opacity:0;
    animation:fill-arrow 0.8s linear forwards 2s;    
}
@keyframes fill-arrow {
    to {
        opacity:1;
    }
}
#loading{
    width:100vw;
    height:100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color:rgba(white,1);
    z-index: 1012;
    overflow-y: scroll;
    overflow-x: hidden;
    svg{
        position:absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
        
    }
    @media (max-width:640px){
        svg{
            transform: scale(1.7);
        }
    }
}
// .uk-modal-page{
//     overflow-y: hidden !important;
//     @media (min-width:640px){
//         //padding-right: 17px !important;
//         header{
//             position: absolute;
//             //width:calc(100vw - 17px);
//         }
//     }
    
// }

@media (min-width:1600px){
    canvas{
        height: 30vh !important;
    }
}
@media (min-width:992px){
    canvas{
        position: fixed;
        bottom:0%;
        left:-30%;
        width:160%;
        height: 25vh;
        opacity: 0;
        transition: opacity 0.6s;
        z-index: -1;
    }
    canvas.mdown{
        top:0%;
        bottom:inherit;
    }
    canvas.alpha{
        opacity: 1;
    }
}
@media (max-width:991px){
    canvas{
        display: none;
    }
}