//overwrite
body{
    @include font-color(pxToEm(14),$fontcolor);
    
    background-color: $white;
    line-height:1.5;
    letter-spacing: 0.1em;
    overflow-x: hidden;
    opacity: 0;
    visibility: hidden;
    &.modal_open{
        height: 100vh;
        overflow: hidden;
        position: fixed;
    }
    &.fade-in{
        opacity: 1;
        transition:opacity  2s ease;
        visibility: visible;
    }
    @media (max-width: 640px){
        
    }
}
#Wapper{
    padding-top: 250px;
}
@media (max-width: 991px){
    #Wapper{
        padding-top: 100px;
    }
}
@media (max-width: 640px){
    #Wapper{
        padding-top: 60px;
    }
}

.overlay{
    background-color:rgba(0,0,0,0.6);
    position: fixed;
    top:0;
    left:0;
    width:100%;
    height:100%;
    display:none;
    z-index: 99;
}
.modal-content{
    max-width: 1000px;
    width:90%;
    height: 90%;
    overflow-y:scroll; 
    position: fixed;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    z-index: 999;
    display: none;
  }
.row{
    max-width:1200px;
    margin:0 auto;
    position: relative;
    @media (max-width: 991px){
        // max-width:640px;
    }
    @media (max-width: 991px){
        .col-12{
            padding:0 4.8%;
        }
    }
}
.rowbox{
    max-width:1230px;
    padding:0 15px;
    margin:0 auto;
    position: relative;
    @extend .clearfix;
    @media (max-width: 1200px){
        padding:0 30px;
    }
    @media (max-width: 640px){
        padding:0 4.8%;
    }
}
.w800{
    max-width: 830px;
    margin:  0 auto;
    @media (max-width: 1200px){
        max-width: 860px;
        padding:0 4.8%;
        .rowbox{
            padding:0;
        }
    }
}
.pos-middle{
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
}
.bgcover{
    width:100%;
    height:0;
    background-size: cover;
}
.banner{
    padding-bottom: 19%;
    position: relative;
    background-position: center;
    h6{
        @include font-color(pxToEm(20),white,700);
        letter-spacing: 0.55em;
    }
    @media (max-width:767px){
        padding-bottom: 39%;
    }
}
.default_motion{
    transition: all 0.6s;
}
.btn{    
    height: 25px;
    line-height: 23px;
    @include border(8px);
    color:$fontcolor;
    border:1px solid $fontcolor;
    background-color: rgba(white,1);
    display: inline-block;    
    padding:0 10px;
    text-align: center;
    font-size: pxToEm(12);
    &:hover{
        color:$fontcolor;
    }
    @media (min-width:641px){
        &:hover{
            background-color: rgba($green,1);
            border:1px solid $green;
            color:white;
        }
    }
    @media (max-width:640px){
        // width:145px;
        height: 40px;
        line-height: 38px;
        // padding:0 25px;
        @include border(10px);
    }
}
.btngroup{
    display: flex;
    align-items: center;
    justify-content: center;
    margin:100px auto;
    a{
        display: inline-block;
        @include font-color(pxToEm(16),$fontcolor,700);
        font-size: 1.04rem;
        margin:0 35px;
        letter-spacing: 0.6em;
        &:after{
            width:calc(100% - 0.5em);
            margin-top: 5px;
            content:' ';
            display: block;
            height:1px;
            background-color: $green;
            opacity: 0;
            @extend .default_motion ;
        }
        &.active{
            color: $green;
            &:after{
                opacity: 1;
            }
        }
    }
    @media (min-width:641px){
        a:hover{
            color: $green;
            &:after{
                opacity: 1;
            }
            
        }
    }
    @media (max-width:640px){
        justify-content: space-around;
        margin:30px 4.8% 40px;
        a{
            font-size:0.95rem;
            letter-spacing: 0;
            margin:0;
            &:after{
                width:100%;
            }
        }
    }
}
