.headerbg{
    max-width: 1200px;
    margin:0 auto;
    position: relative;
}
header{
    position: fixed;
    top:0;
    left:0;
    z-index: 1011;
    width:100%;
    background-color:rgba(white,1); 
    transition: top 0.6s ease;
	.logo{
		position: absolute;
		left:0px;
		top:50%;
		transform: translateY(-50%);
        z-index: 11;
        display: flex;
        justify-content: center;
        align-items: center;
        img{
            width:240px;
        }
        // span{
        //     margin-left: 5px;
        //     @include font-color(pxToEm(16.5),$fontcolor,700);
        //     line-height: 1.2;
        // }
        @media (max-width: 1365px){
            left:30px;
            
        }
		@media (max-width: 640px){
            // left:20px;
            img{
                width:140px;
            }
            // span{
            //     font-size: pxToEm(12);
            // }
		}
    }
    ul.mmenu{
        display: flex;
        justify-content:flex-end;
        // align-items: center;
		> li:not(.langubtn){
            margin-left: 15px;
            > a{
                position: relative;
                display:block;
                text-align:center;	
                @include font-color(pxToEm(14),#444853,inherit);
                -webkit-writing-mode: vertical-rl;
                writing-mode: vertical-rl;
                line-height: 30px;
                width: 25px;
                letter-spacing: 0.3em;      
            }
            a:after{
                content:' ';
                width:100%;
                height:calc(100% - 0.4em);
                position: absolute;
                top:0%;
                left:0;
                border-left:1px solid rgba($green,0);
                z-index: -1;
            }            
            &.active {
                > a{color:$green};
                > a:after{
                    border-left:1px solid rgba($green,1);
                }
            }	
            &.hasmenu > a:before{
                @include triangle_sosceles(top,14px,8px,#e6e6e6);
                position: absolute;
                bottom: -18px;
                left:50%;
                transform: translateX(-50%);
                opacity: 0;
            }
            @media all and (-ms-high-contrast:none){
                a:after{
                    display: none;
                }
                span{
                    border-left:1px solid rgba($green,0);
                    width:28px;
                } 
                &.hasmenu > a:before{
                    bottom: 35px;
                    left:9px;
                }
            }
                
        }
        li.langubtn{
            height: 85px;
            margin-left: 15px;
            a{
                display: block;
                width:32px;
                height:22px;
                line-height: 20px;
                border:1px solid #444853;
                background-color:rgba(white,0);
                @include font-color(pxToEm(14),#444853,inherit);
                @include border(5px);
                text-align: center;
            }
            a:nth-child(1){
                margin-bottom: 10px;
            }
            a.active{
                color:white;
                background-color: $green;

            }
        }
        a span{
            position: relative;
        }
        @media (min-width:1366px){
            transform: translateX(55px);
        }
		@media (min-width: 992px){
			
			height: 175px;
            
            padding-top: 50px;
			> li:not(.langubtn){
                > a:not(*:root) {
                    position: relative;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                }
                &:hover {
                    > a{color:$green};
                    > a:after{
                        border-left:1px solid rgba($green,1);
                    }
                    > a:before{
                        opacity: 1;
                    }
                    ul.subBox{
                        visibility: visible;
                        opacity: 1;
                    }
                }
            }
            
        }
        @media (min-width: 992px) and (-ms-high-contrast:none){
            > li:not(.langubtn) > a{
                position: relative;
                display: flex;
                justify-content: flex-start;
                align-items: flex-start !important;
            }
            > li:not(.langubtn):hover{
                span{
                    border-left:1px solid rgba($green,1);
                } 
            }
            
        }
		@media (max-width: 991px){
            align-items: flex-start;	
            justify-content: center;
            position: relative;
            height: 280px;
            > li:not(.langubtn){
                margin:0 10px;
                > a{
                    line-height: 30px;
                    width: 30px;
                }
                &.active {
                    ul.subBox{
                        visibility: visible;
                        opacity: 1;
                    }
                }
            }
        }
        @media (max-width: 991px) and (-ms-high-contrast:none){
            > li:not(.langubtn){
                span{
                    position: absolute;
                    top:0;
                }
                &.hasmenu.active span{
                    border-left:1px solid rgba($green,1);
                }
            }
        }
        @media (max-width:640px){
            li.langubtn{
                position: absolute;
                right:25px;
            }
        }
	}//end mmenu

    ul.subBox{		
        position: absolute;
        z-index:10;        
        opacity: 0;
        visibility: hidden;
        display: block;
        li{
            float: left;
            margin: 0 5px;

        }
        a{
            display:block;
            text-align:center;
            @include font-color(pxToEm(14),#444853,inherit);
            height: 35px;
            line-height: 35px;
            padding:0 10px;
            text-align: right;
        }
        a.active{
            background-color:$green;
            color:white; 
        }
        @media (min-width:992px){
            bottom:0px;
            a:hover,a.active{
                background-color:$green;
                color:white; 
            }
        }
        @media (max-width:991px){   
            top:120px;
            padding-top:25px;
            border-top: 1px solid $fontcolor;
            min-width: 180px;
            li{
                margin:0 20px 0 0;
            }
            a{
                height: auto;
                -webkit-writing-mode: vertical-rl;
                writing-mode: vertical-rl;
                line-height: 30px;
                width: 30px;
                letter-spacing: 0.3em;   
                padding:15px 0;
                @include border(5px);
            }
            
        }
        @media (max-width: 991px) and (-ms-high-contrast:none){
            display: flex;
            height:40vh;
            li{
                position: relative;
                width:30px;
            }
            a{
                position: absolute;
                top:0;
                left:0;
            }
        }
            
    }//end subbox
    
    .menuicon{
        position: absolute;
        right:0;		
		height: 100%;
		z-index: 10;        
        width:40px;
        height:30px;
        padding-right: 20px;
        margin-right: 20px;
        margin-top: 40px;
        .mpsrel{
            width:100%;
            height: 100%;
        }
        .bar,.bar:after,.bar:before{
            transition: all .25s;
            content: "";
            position: absolute;
            right: 0;	  
            bottom:0;
            height: 25px;
            width: 1px;
            background:$fontcolor;
         }
         .bar:after{right: 10px;height: 30px;}
         .bar:before{right: -10px;height: 30px;}	

		&.menu-expanded .bar{
			background:rgba($fontcolor,0);
			&:before{
				right:0;
				transform: rotate(-45deg);
				background-color: $fontcolor;

			}
			&:after{
				right:0;
    			transform: rotate(45deg);
				background-color: $fontcolor;

			}
		}
		@media (min-width: 992px){
			display: none;
		}
		@media (max-width: 640px){
			// top:16px;	
            // right:20px;
            margin-top: 17px;
		}
    }
    
    @media (min-width: 992px){
        .bggray{
            display: block;
            width:100%;
            height: 35px;
            background-color: #e6e6e6;
            position: absolute;
            bottom:0px;
            left:0;
            opacity: 0;
            visibility: hidden;
            z-index: -1;
            // box-shadow: 0px 2px 4px rgba(0,0,0,.2);
            // border-bottom:20px solid white;
        }
        .bggray.mhover{
            visibility: visible;
            opacity: 1;
        }
        .menu_switch{
            position: fixed;
            top:50px;
            right:30px;
            cursor: pointer;
            opacity: 0;
            visibility: hidden;          
            transition: all 0.6s;
            z-index: 999;
            width:30px;
            display: block;
            text-align: center;
            line-height: 1.3;
            span{
                margin-top: 5px;
                display: inline-block;
            }
        }
        &.nav-up{
            top:-195px;
            .menu_switch{
                opacity: 1;
                visibility: visible;
            }        
        }    
    }
    @media (min-width:992px) and (max-width:1365px){
        .navBox{
            padding-right: 30px;
        }
    }
    @media (max-width:991px){	
        .headBox{
			position: relative;
			height: 100px;
			width: 100%;
			background-color:white;
            z-index: 10;
        }
        .navBox{
            display: none;
            width:100%;
            height:80vh;
            padding-top: 20vh;
            position: absolute;
			top:100px;
			left:0;
			z-index: 9;
            background-color:#e6e6e6;
            
        }
        .menu_switch{
            display: none;
        }
    }
    @media (max-width:640px){	
        .headBox{
            height: 60px;
        }
        .navBox{
            top:60px;
            overflow-y:scroll; 
			-webkit-overflow-scrolling: touch;
        }
    }
}
